import React, { ReactElement, FC, useState, useEffect, useCallback } from "react";
import { Box, Typography, TextField, Paper, AppBar } from "@mui/material";
import { Formik, Field, Form, ErrorMessage, FormikHelpers, useFormik} from "formik";
import { useField } from 'formik';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import { editTransaction } from "../../../services/accounting.service";
import * as Yup from "yup";
import CoreTextInput from "../Core/CoreTextInput";
import CoreCheckbox from "../Core/CoreCheckbox";
import { CoreMuiTextField } from "../Core/CoreMuiTextField";
import CoreSelect from "../Core/CoreSelect";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
    LinearProgress,
    MenuItem,
    FormControl,
    FormControlLabel,
    AutocompleteRenderInputParams,
    ToggleButton,
  } from '@mui/material';
  import {LocalizationProvider} from '@mui/lab';
  import AdapterDateFns from '@mui/lab/AdapterDateFns';
  import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
  import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
  import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
  import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
  import MuiTextField from '@mui/material/TextField';
  import {
    Autocomplete,
    Select,
    Switch,
    ToggleButtonGroup,
  } from 'formik-mui';
  import { lostPasswordOptionData } from "../Core/CoreData";
// Testing
import 'react-app-polyfill/ie11';
import * as ReactDOM from 'react-dom';
import { Windows } from "react-bootstrap-icons";
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import { ITransaction } from "../../../components/DataModels/Budget/UserBudgetModel";
import CoreMuiSelect from "../Core/CoreMuiSelect";

interface Props {
    transaction: ITransaction,
    categories: any
}
export interface LostPasswordData {
	description: string,
    confirm: boolean,
}

enum SubmitResponse {
    SUCCESS = "SUCCESS",
    NONE = "NONE",
    FAILED = "FAILED",
    ERROR = "ERROR",
}
export const EditTransactionForm = ({...props}:Props): JSX.Element => {
    
    let transaction:ITransaction = props.transaction;
    let categories:any = props.categories;
    const {t, i18n} = useTranslation('common');
    const [responseSuccess, setResponseSuccess] = useState<SubmitResponse>(SubmitResponse.NONE)
    const [responseError, setResponseError] = useState<SubmitResponse>()
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [isPosted, setIsPosted] = useState<boolean>(false);
    const [responseData, setResponseData] = useState<ITransaction|null>(null);
    let navigate: NavigateFunction = useNavigate();
    
    const initialValues = {
        textRule: transaction.originalDescription ?? "",
        categoryId: transaction.categoryId,
        lock: 0,
    };
    
    const validationSchema = Yup.object({
        textRule: Yup
        .string()
        .required()
        .trim()
        .matches(
            /^.*((?=.*[A-Åa-å0-9]){1}).*$/,
            t('pages.my.budget.modal.input-textrule-validation-error')+" '"+ transaction.originalDescription+"'"
        )
        .test('textRule', t('pages.my.budget.modal.input-textrule-validation-error'), function (textRule) {
        const text = transaction.originalDescription.toLowerCase();
        if (text.match((textRule??"").toLowerCase())) {
            return true;
        } else {
            return false;
        }
        })
      });
    
    const onFormSubmit = useCallback(async (values: any, { setSubmitting }: any) => {
        setLoading(true);
        setIsPosted(true);
        
        editTransaction(transaction.id, values.categoryId, values.textRule, values.lock)
        .then(
            (response) => {
                
              setLoading(false);
              setSuccess(response.success);
              setResponseData(response.data);
            },
            (error) => {
                console.log("editTransaction error");
                console.log(error.response.data);
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    (error.response &&
                    error.response.data) ||
                    error.message ||
                    error.toString()
                ;
                setLoading(false);
                setMessage(resMessage);
                setSuccess(false);
            }
          );

        if (!message && success) {
            setResponseSuccess(SubmitResponse.SUCCESS)
            setResponseError(SubmitResponse.NONE)
            setMessage("");
        } else if (message && !success) {
            // report back errors
            setResponseError(SubmitResponse.FAILED)
        } else {
            // Special case?
            setResponseError(SubmitResponse.ERROR)
        }

	}, []);

    if(success && isPosted && !message && responseData != null) {
        return (
        <Grid item textAlign={("left")}>
             <p>{t('pages.my.budget.modal.edit-transaction-success-message')}</p>
        </Grid>)
    }
    if(!success && isPosted && responseData == null) {
        return (
        <Grid item textAlign={("left")}>
             <p>{t('pages.my.budget.modal.edit-transaction-error-message')}</p>
             <p>{message}</p>
        </Grid>)
    }
    
    if(responseSuccess == SubmitResponse.SUCCESS) {
        return (<div>{message}</div>)
    }
    return (
        <div>
        <Paper
            variant="elevation"
            elevation={0}
        >
        <Grid item textAlign={("left")}>
            <p className="ch-text-center ch-mb-12">{t('pages.lostpassword.LostPasswordFormText')}</p>
            {
                (responseError === SubmitResponse.FAILED) ?
                    <div>
                        <p>{t('pages.lostpassword.ErrorLostPassword')}</p>
                    </div>
                : (responseError === SubmitResponse.ERROR) ?
                    <div>
                        <p>{t('pages.lostpassword.ErrorLostPassword')}</p>
                    </div>
                : null
            }
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onFormSubmit}
            >
                <Form>
                    <Box margin={1}>
                        
                    <p>{t('pages.my.budget.modal.description-header')}:</p>
                    <p>{transaction.originalDescription}</p>
                    </Box>
                    
                    <Box margin={1} marginTop={4}>
                        <Field
                            component={CoreMuiTextField}
                            name="textRule"
                            type="text"
                            label={t('pages.my.budget.modal.input-textrule-label')}
                            helperText={t('pages.my.budget.modal.input-textrule-helpertext')}
                            fullWidth
                        />
                    </Box>

                    <Box margin={1} marginTop={4}>
                        <Field 
                            component={CoreMuiSelect}
                            name="categoryId" 
                            type="text"
                            label={t('pages.my.budget.modal.input-category-label')}
                            options={categories.categories}
                            value={transaction.categoryId}
                            >
                        </Field>
                    </Box>

                    <Box margin={1} marginTop={4}>
                        <FormControlLabel
                        control={
                            <Field component={Switch} 
                                disabled
                                type="checkbox" 
                                name="lock"
                            />
                        }
                        label={t('pages.my.budget.modal.input-lock-label')}
                        />
                        <FormHelperText>{t('pages.my.budget.modal.input-lock-helpertext')}</FormHelperText>
                    </Box>
                    
                    <Box margin={1} marginTop={4} marginBottom={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="button-block"
                            disabled={loading}
                        >
                            {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>{t('pages.my.budget.modal.input-submit-label')}</span>
                        </Button>
                    </Box>
                    <Box>
                        {message && (
                            <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                            </div>
                        )}
                    </Box>
                    
                    </Form>
            </Formik>
        </Grid>
        </Paper>
        </div>
    )
}

export default EditTransactionForm;