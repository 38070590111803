import{ FC, ReactElement, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getOauthInstitutionIdsData, getOauthConnectData } from "../../../services/oauth.service";
import { NavigateFunction, useNavigate, useLocation } from 'react-router-dom';
import CoreBorderBox from "../Core/CoreBorderBox";
import { Helmet } from "react-helmet"


/*
export function AddCss(url:any) {
  const link = document.createElement('link');
  link.rel  = 'stylesheet';
  link.type = 'text/css';
  link.href = url;
  document.body.appendChild(link);
}

export function AddScript(url:any) {
  
  var script2 = document.querySelector(`script[src="${url}"]`);
  if(!script2) {
    var script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
  }
  
}
*/

export const OauthConnectModal: FC = (): ReactElement => {
  const { t, i18n } = useTranslation('common');
  const search = useLocation().search;
  const institutionId = new URLSearchParams(search).get('id');
  const status = new URLSearchParams(search).get('status');
  const errorMessage = new URLSearchParams(search).get('message');
  const [oauthInstitutionIds, setOauthInstitutionIds] = useState<any>();
  const [oauthConnectLink, setOauthConnectLink] = useState<any>();

  let navigate: NavigateFunction = useNavigate();

  async function fetchData() {
    const response = await getOauthInstitutionIdsData();
    const json = await response.data;
    setOauthInstitutionIds(json.data.institutionIds);
  }
  async function fetchConnectData(id: string) {
    const response = await getOauthConnectData(id);
    const json = await response.data;
    setOauthConnectLink(json.data.link);
  }
  useEffect(() => {
      if (oauthInstitutionIds == null) {
        fetchData();
      }
      if (oauthConnectLink == null && institutionId != null && oauthInstitutionIds != null) {
        fetchConnectData(institutionId);
      }
  },);

  if(oauthConnectLink != null) {
    localStorage.removeItem("status");
    window.location.replace(oauthConnectLink);
  }

  if (institutionId != null) {
    return (
      <div></div>
    );
  }

  if (oauthInstitutionIds != null && institutionId == null) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "left",
          paddingTop: "0px",
          paddingBottom: { xs: "40px", md: "90px" }
        }}
      >
        <Box>
          <CoreBorderBox>
            {status && errorMessage != null && (
              <div className="text-center">
              <div>{t('pages.my.oauth.errorMessageHeading')}</div>
              <div>{errorMessage}</div>
              <div>{t('pages.my.oauth.errorMessageDescription')}</div>
              </div>
            )}
            <div id="institution-content-wrapper"></div>
            <Helmet>
              <link href="/resources/nordigen-ui.css" rel="stylesheet" />
            </Helmet>
            <Helmet>
              <script src="/resources/nordigen-ui.js"></script>
            </Helmet>
          </CoreBorderBox>
        </Box>
      </Box>
    );
  };
  /* <script src="https://bitsker.blob.core.windows.net/package-mypage/loader/resources/nordigen-ui.js"></script> */
  /* <script src="/resources/nordigen-ui.js"></script> */
  return (
    <></>
  )
}

export default OauthConnectModal;