import { useState, useCallback } from "react";
import { Box, Paper } from "@mui/material";
import { Formik, Field, Form } from "formik";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import { editBudgetCategory } from "../../../services/budget.service";
import * as Yup from "yup";
import { CoreMuiTextField } from "../Core/CoreMuiTextField";
import { NavigateFunction, useNavigate } from 'react-router-dom';
// Testing
import 'react-app-polyfill/ie11';
import FormHelperText from '@mui/material/FormHelperText';
import { IBudgetCategory, ITransaction } from "../../DataModels/Budget/UserBudgetModel";
import CoreMuiSelect from "../Core/CoreMuiSelect";

interface Props {
    budgetCategory: IBudgetCategory,
    budgetAccountedLine: ITransaction,
    isSubLine: boolean
}

interface SubmitRequest {
    id: string,
    amount: number,
    expenseType: number,
}
export interface LostPasswordData {
	description: string,
    confirm: boolean,
}

enum SubmitResponse {
    SUCCESS = "SUCCESS",
    NONE = "NONE",
    FAILED = "FAILED",
    ERROR = "ERROR",
}
export const ViewEditBudgetCategory = ({...props}:Props): JSX.Element => {
    
    let budgetCategory:IBudgetCategory = props.budgetCategory;
    let budgetAccountedLine:ITransaction = props.budgetAccountedLine;
    let isSubLine:boolean = props.isSubLine;
    let expenseType:number = budgetCategory?.expenseType;
    const {t, i18n} = useTranslation('common');
    const [responseSuccess, setResponseSuccess] = useState<SubmitResponse>(SubmitResponse.NONE)
    const [responseError, setResponseError] = useState<SubmitResponse>()
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [isPosted, setIsPosted] = useState<boolean>(false);
    const [responseData, setResponseData] = useState<SubmitRequest>(); //ITransaction|null
    let navigate: NavigateFunction = useNavigate();
    
    const initialValues = {
        amount: budgetCategory?.amount,
        id: budgetCategory?.id,
        expenseType: expenseType,
    };
    const validationSchema = Yup.object({
        amount: Yup.string().required('Required'),
        expenseType: Yup.number()
      });
    
    const onFormSubmit = useCallback(async (values: SubmitRequest, { setSubmitting }: any) => {
        setLoading(true);
        setIsPosted(true);
        
        editBudgetCategory(budgetCategory.id, values.amount, values.expenseType)
        .then(
            (response) => {
                
              setLoading(false);
              //setSuccess(response.status);
              setSuccess(true);
              setResponseData(response.data);
            },
            (error) => {
                console.log("editBudgetCategory error");
                console.log(error.response.data);
                
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    (error.response &&
                    error.response.data) ||
                    error.message ||
                    error.toString()
                ;
                setLoading(false);
                setMessage(resMessage);
                setSuccess(false);
            }
          );

        if (!message && success) {
            setResponseSuccess(SubmitResponse.SUCCESS)
            setResponseError(SubmitResponse.NONE)
            setMessage("");
        } else if (message && !success) {
            // report back errors
            setResponseError(SubmitResponse.FAILED)
        } else {
            // Special case?
            setResponseError(SubmitResponse.ERROR)
        }

	}, []);

    if(success && isPosted && !message && responseData != null) {
        return (
        <Grid item textAlign={("left")}>
             <p>{t('pages.my.budget.modal.edit-budget-category-success-message')}</p>
        </Grid>)
    }
    if(!success && isPosted && !loading && responseData == null) {
        return (
        <Grid item textAlign={("left")}>
             <p>{t('pages.my.budget.modal.edit-budget-category-error-message')}</p>
             <p>{message}</p>
        </Grid>)
    }

    if (isPosted && loading) {
        return (<div>{t('pages.my.budget.modal.edit-budget-category-loading-message')}</div>)
    }
    
    if(responseSuccess == SubmitResponse.SUCCESS) {
        return (<div>{message}</div>)
    }
    return (
        <div>
        <Paper
            variant="elevation"
            elevation={0}
        >
        <Grid item textAlign={("left")}>
            {
                (responseError === SubmitResponse.FAILED) ?
                    <div>
                        <p>{t('pages.lostpassword.ErrorLostPassword')}</p>
                    </div>
                : (responseError === SubmitResponse.ERROR) ?
                    <div>
                        <p>{t('pages.lostpassword.ErrorLostPassword')}</p>
                    </div>
                : null
            }
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onFormSubmit}
            >
                <Form>
                    <Box margin={1}>
                    <p>{t('pages.my.budget.modal.edit-budget-category-account-name')}: {budgetCategory?.account}</p>
                    <p>{t('pages.my.budget.modal.edit-budget-category-original-amount')}: {budgetCategory?.amount}</p>
                    </Box>
                    
                    {!isSubLine && (
                    <Box margin={1} marginTop={4}>
                        <Field
                            component={CoreMuiTextField}
                            name="amount"
                            type="text"
                            label={t('pages.my.budget.modal.edit-budget-category-input-amount-label')}
                            helperText={t('pages.my.budget.modal.edit-budget-category-input-amount-helpertext')}
                            
                        />
                    </Box>
                    )}

                    {isSubLine &&(
                    <Box margin={1} marginTop={4}>
                        <Field 
                            component={CoreMuiSelect}
                            name="expenseType" 
                            type="text"
                            label={t('pages.my.budget.modal.edit-budget-category-input-expensetype-label')}
                            
                            options={[
                                {"value":0,"text":t('pages.my.budget.modal.edit-budget-category-select-option-expensetype-variable')},
                                {"value":1,"text":t('pages.my.budget.modal.edit-budget-category-select-option-expensetype-fixed')}
                            ]}
                            value={expenseType}
                            >
                        </Field>
                        <FormHelperText>{t('pages.my.budget.modal.edit-budget-category-input-expensetype-helpertext')}</FormHelperText>
                    </Box>
                    )}
                    
                    <Box margin={1} marginTop={4} marginBottom={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="button-block"
                            disabled={loading}
                        >
                            {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>{t('pages.my.budget.modal.edit-budget-category-input-submit-label')}</span>
                        </Button>
                    </Box>
                    <Box>
                        {message && (
                            <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                            </div>
                        )}
                    </Box>
                    
                    </Form>
            </Formik>
        </Grid>
        </Paper>
        </div>
    )
}
