import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
/*
//
import { CssBaseline } from "@mui/material";
//import { createTheme } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@material-ui/core";
*/
import MyRouter from "./MyRouter";
import { COLOR_BLACK, COLOR_PINK_MAIN_TEXT } from "./Config/Constants";

function App() {
  // define theme
  // ref material.io/design/color/the-color-system.html#tools-for-picking-colors
  const theme = createTheme({
    palette: {
      primary: {
        light: COLOR_PINK_MAIN_TEXT,
        main: COLOR_PINK_MAIN_TEXT,
        dark: COLOR_PINK_MAIN_TEXT,
        contrastText: COLOR_BLACK,
      },
      secondary: {
        light: COLOR_PINK_MAIN_TEXT,
        main: COLOR_PINK_MAIN_TEXT,
        dark: COLOR_PINK_MAIN_TEXT,
        contrastText: COLOR_BLACK,
      },
      text: {
        primary: COLOR_BLACK,
        secondary: COLOR_BLACK,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MyRouter />
    </ThemeProvider>
  );
}

export default App;
