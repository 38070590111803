import React, { FC, ReactNode } from "react";
import { Box, CssBaseline } from "@mui/material";
import LogoOauth from '../../assets/image/logologin.png'
import Logo from '../../assets/image/logo.png'
import { LoginPageHeader } from "../Elements/Login/LoginPageSwitch"

interface LayoutProps {
  children: ReactNode;
}

const LayoutOauth: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "0px",
        paddingBottom: { xs: "0px", md: "0px" }
      }}
    >
        <div className="tw-handle">
          <div className="oauth-container">
            <div className="oauth-form-container">
              <div>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "0rem", marginTop: "2rem", marginBottom: "2rem"}}>
                  <a href="/my/dashboard"><img src={Logo} className="h-[90px]" title="Bitsker" alt="Logo Bitsker" /></a>
                </Box>
                {children}
              </div>
            </div>
            <div className="oauth-image-container">
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "0rem", marginTop: "2rem", marginBottom: "2rem"}}>
                <img src={LogoOauth} className="h-[90px]" title="Bitsker" alt="Logo oauth" />
              </Box>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default LayoutOauth;