import React, { FC, ReactElement, useState, useEffect, useCallback } from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Link, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAccountsData, getTransactionsData } from "../../../services/totals.service";
import IUserTotalsAccounts from "../../DataModels/Dashboard/UserTotalsAccountsModel";
import IUserTotalsTransactions from "../../DataModels/Dashboard/UserTotalsTransactionsModel";

import EditIcon from '@material-ui/icons/Edit';
import SettingIcon from '@material-ui/icons/Settings'
import DashboardElementUserTotalsTransactionsAccounts from "../../Elements/Dashboard/UserTotalsAccounts";
import DashboardElementUserTotalsSummary from "../../Elements/Dashboard/UserTotalsSummary";
import CoreMonthDatePicker from "../../Elements/Core/CoreMonthDatePicker";
import CoreBorderBox from "../../Elements/Core/CoreBorderBox";
import CoreBorderBoxInner from "../../Elements/Core/CoreBorderBoxInner";
import { GetPeriod, SetPeriod } from "../../../services/period.service";
import { COLOR_PURPLE_DARK_BG } from "../../../Config/Constants";

export const DashboardAccounts: FC = (): ReactElement => {
  const { t, i18n } = useTranslation('common');
  
  const [period, setPeriod] = useState<any>(GetPeriod());
  const periodHandler = (date: any) => {
      SetPeriod(date);
      setPeriod(GetPeriod());
  };

  const [userTotalsAccounts, setUserTotalsAccounts] = useState<IUserTotalsAccounts>();
  async function fetchAccountsData() {
    const response = await getAccountsData(period);
    const json = await response.data;
    setUserTotalsAccounts(json.data);
  }
  // // eslint-disable-next-line no-restricted-globals
  // // a = location;
  useEffect(() => {
    fetchAccountsData();
  }, [period]);

  return (
    <Box>
      <CoreBorderBox flexDirextionXs="row">
        <Typography component="p" variant="h5" color="text.secondary">
          {t('pages.my.dashboard.heading')}
          <Typography component="span" color="text.secondary" >
            <span> </span>
          </Typography>
        </Typography>
        <CoreMonthDatePicker
          text={t('core.date-month-picker-period')}
          date={period}
          onChange={periodHandler}
        />
      </CoreBorderBox>
      <CoreBorderBox>
        <CoreBorderBoxInner heading={t('pages.my.dashboard.usertotals-transactions-accounts-heading')}>
          
          
          <DashboardElementUserTotalsTransactionsAccounts accounts={userTotalsAccounts?.accounts} />
          <Box>
            <Box sx={{ p: '8px',  m: 0, maxWidth: '40px', float: 'left' }}>
              <Link
                  className="frontpage-job"
                  to="/my/dashboard/accounts/edit"
                  state={{ previousLocation: "" }}
              >
                  <EditIcon fontSize="small" style={{ color: COLOR_PURPLE_DARK_BG }} />
              </Link>
            </Box>
            <Box sx={{ p: '8px',  m: 0, maxWidth: '40px', float: 'left' }}>
              <Link
                  className="frontpage-job"
                  to="/my/dashboard/accounts/settings"
                  state={{ previousLocation: "" }}
              >
                  <SettingIcon fontSize="small" style={{ color: COLOR_PURPLE_DARK_BG }} />
              </Link> 
            </Box>
          </Box>
        </CoreBorderBoxInner>
        <CoreBorderBoxInner heading={t('pages.my.dashboard.usertotals-summary-heading')}>
          <DashboardElementUserTotalsSummary period={period} />
          <div style={{ paddingTop: 30 }}> </div>
          <Link
            className="frontpage-job"
            to="/my/dashboard/transactions/all"
            state={{ previousLocation: "" }} // window.location
          >
            
            <Button
                variant="contained"
                color="primary"
                type="submit"
                className="button-block"
            >
                <span>{t('pages.my.dashboard.usertotals-transactions-transaction-show-list-button')}</span>
            </Button>
          </Link>
        </CoreBorderBoxInner>
      </CoreBorderBox>
    </Box>
  );
};
