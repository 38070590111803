export interface IStatus {
  hasAccountingLines: boolean,
  hasCompleted: boolean,
  hasIntegrated: boolean,
  isLoaded: boolean,
  isSynced: boolean,
  syncDataAccounts: boolean,
  syncDataTransactions: boolean,
  syncDataProcessing: boolean,
  get IsInitiated(): boolean;
  get IsIntegrated(): boolean;
  get IsLoaded(): boolean;
  get IsSynced(): boolean;
  get IsSyncDataAccounts(): boolean;
  get IsSyncDataTransactions(): boolean;
  get IsSyncDataProcessing(): boolean;
  SetHasAccountingLines(): void,
  SetHasCompleted(): void,
  SetHasIntegrated(): void,
  SetInitiated(): void,
  SetLoaded(): void,
  SetSyncDataAccounts(): void,
  setSyncDataTransactions(): void,
  setSyncDataProcessing(): void
}

export interface IUserSyncDataAccounts {
  statusDone: boolean
}

export interface IUserSyncDataTransactions {
  statusDone: boolean
}

export interface IUserSyncDataProcessing {
  statusDone: boolean
}


export class StatusModel<IStatus> {
  public hasAccountingLines: boolean;
  public hasCompleted: boolean;
  public hasIntegrated: boolean;
  public isInitiated: boolean;
  public isLoaded: boolean = false;
  public isSynced: boolean = false;
  public syncDataAccounts: boolean = false;
  public syncDataTransactions: boolean = false;
  public syncDataProcessing: boolean = false;

  constructor(props : any) {
    this.hasAccountingLines = props?.hasAccountingLines ?? false;
    this.hasCompleted = props?.hasTransactions ?? false;
    this.hasIntegrated = props?.hasIntegrated ?? false;
    this.isInitiated = props?.isInitiated ?? false;
    this.isLoaded = props?.isLoaded ?? false;
    this.isSynced = props?.isSynced ?? false;
    this.syncDataAccounts = props?.syncDataAccounts ?? false;
    this.syncDataTransactions = props?.syncDataTransactions ?? false;
    this.syncDataProcessing = props?.syncDataProcessing ?? false;
  }

  public get IsIntegrated(): boolean {
    return this.hasIntegrated;
  }

  public get IsInitiated(): boolean {
    return this.isInitiated;
  }

  public get IsLoaded(): boolean {
    return this.isLoaded;
  }

  public get IsSynced(): boolean {
    return this.isSynced;
  }

  public get IsSyncDataAccounts(): boolean {
    return this.syncDataAccounts;
  }

  public get IsSyncDataTransactions(): boolean {
    return this.syncDataTransactions;
  }

  public get IsSyncDataProcessing(): boolean {
    return this.syncDataProcessing;
  }
  
  public SetHasAccountingLines(): void {
    this.hasAccountingLines = true;
  }
  
  public SetHasCompleted(): void {
    this.hasCompleted = true;
  }
  
  public SetHasIntegrated(): void {
    this.hasIntegrated = true;
  }

  public SetInitiated(): void {
    this.isInitiated = true;
  }

  public SetLoaded(): void {
    this.isLoaded = true;
  }

  public SetSynced(): void {
    this.isSynced = true;
  }

  public SetSyncDataAccounts(): void {
    this.syncDataAccounts = true;
  }

  public setSyncDataTransactions(): void {
    this.syncDataTransactions = true;
  }

  public setSyncDataProcessing(): void {
    this.syncDataProcessing = true;
  }
  
}