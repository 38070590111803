import axios from "axios";
import React, { ReactElement, FC, useState, useEffect } from "react";
import { bool } from "yup";
import {Route} from "../routes";
import EventBus from "../services/EventBus";
import authHeader from "./auth-header";
import jwt_decode from "jwt-decode";
import { APP_API_URL, APP_API_URL_DEV } from "../Config/Constants";
import {IUser, User} from "../components/DataModels/User/User";
import { NavigateFunction, useNavigate } from 'react-router-dom';

var apiUrl = window.location.hostname.includes('localhost') ? APP_API_URL_DEV : APP_API_URL;
const API_URL = apiUrl+"/auth/";

export const signup = (username: string, password: string, passwordConfirm: string, membership?: string) => {
  return axios
    .post(API_URL + "signup", {
      username,
      password,
      passwordConfirm,
      membership
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};


export const login = (username: string, password: string) => {
  return axios
    .post(API_URL + "login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      
      return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("status");
};

export const lostPassword = (email: string) => {
  
  return axios
    .post(API_URL + "lostPassword", {
      email,
    })
    .then((response) => {
      return response.data;
    });
};

export const resetPassword = (email: string, password: string, code: string) => {
  return axios
    .post(API_URL + "resetPassword", {
      email,
      password,
      code
    })
    .then((response) => {
      return response.data;
    });
};

export const checkAuth = ()  => {
  
  //var token = "eyJ0eXAiO.../// jwt token";
  var hasExpired:boolean = true;
  var closeToExpire:boolean = false;
  var token = getCurrentUser()?.token;
  if (token != null) {
    var decoded = jwt_decode(token);
    var userToken:any = decoded;
    var expires = userToken.exp;
    var now = Math.floor(Date.now() / 1000)-10;
    var hasExpired = expires < now;
    var closeToExpire:boolean = (expires - now) < 600; //10min
    //var decodedHeader = jwt_decode(token, { header: true });
  }
  // Refresh and extend user login if close to expire
  if(!hasExpired && closeToExpire) {
    whoAmI().then(
      (response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
      }
    );
  }
  if(hasExpired) {

    whoAmI().then(
      (response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          //setContent(_content);

        if (error.response && error.response.status === 401) {
          localStorage.removeItem('user'); // remove and logout
          EventBus.dispatch("logout");
        }
      }
    );
  }
};


export const updateWhoAmI = () => {
  checkAuth();
  whoAmI().then(
    (response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
    }
  );
};

export const whoAmI = () => {
  var response = axios.get(API_URL + "whoAmI", { headers: authHeader() });
  
  return response;
};

export const getCurrentUser = (): User<IUser>|null => {
  const userStr = localStorage.getItem("user");
  if (userStr) {
    return new User(JSON.parse(userStr));
  }
  return null;
};

//export const AuthCheck = (route:Route): boolean => {
export const AuthCheck = (authenticate:boolean): boolean => {
  if (authenticate) {
    let currentUser = getCurrentUser();
    let isAuthenticated: boolean = currentUser != null;

    return isAuthenticated;
  }
  
  return true;
  
}

// Check auth and redirect to /my if user is set
export const AuthCheckRedirect = (): void => {
    
  // Navigate redirect to /my if user is set
  let navigate: NavigateFunction = useNavigate();
  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser != null) {
      navigate("/my/dashboard");
    }
  }, []);
  //
}