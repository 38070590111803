import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../services/EventBus";
import { StatusModel, IStatus } from "../components/DataModels/Dashboard/UserInitiateModel";
import { getCurrentUser, checkAuth } from "./auth.service";
import { APP_API_URL, APP_API_URL_DEV } from "../Config/Constants";

var apiUrl = window.location.hostname.includes('localhost') ? APP_API_URL_DEV : APP_API_URL;
const API_URL = apiUrl+"/user/";

function checkAuth2(response: any) {
  console.log(response);
  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      EventBus.dispatch("logout");
    }
}
}

export const getStatusData = () => {
  checkAuth();
  return axios
    .get(API_URL + "status", { headers: authHeader() })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("status", JSON.stringify(response.data));
      }
      
      return response.data;
    });
};

export const getSyncDataAccountsData = () => {
  checkAuth();
  var response = axios.get(API_URL + "syncDataAccounts", { headers: authHeader() });
  return response;
};

export const getSyncDataTransactionsData = () => {
  checkAuth();
  var response = axios.get(API_URL + "syncDataTransactions", { headers: authHeader() });
  return response;
};

export const getSyncDataProcessingData = () => {
  checkAuth();
  var response = axios.get(API_URL + "syncDataProcessing", { headers: authHeader() });
  return response;
};

export const getStatus = (): StatusModel<IStatus> => {
  const statusStr = localStorage.getItem("status") ?? '{"hasAccountingLines":false, "hasTransactions":false, "hasIntegrated":false, "isInitiated": false, "isLoaded":false, "isSynced":false}';
    return new StatusModel(JSON.parse(statusStr));
  
};

export interface CancelIntegrationResponse {
  result: boolean,
}

export const cancelIntegration = () => {
  return axios
    .post(API_URL + "cancelIntegration", {
    }, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};