import React, { FC, ReactNode } from "react";
import { Box, CssBaseline } from "@mui/material";
import { LoginPageHeader } from "../Elements/Login/LoginPageSwitch"
import { logo, logologin } from '../../assets';

interface LayoutProps {
  children: ReactNode;
}

const LayoutLogin: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: { xs: "10px", md: "0px" },
        paddingBottom: { xs: "0px", md: "0px" }
      }}
    >
        <div className="tw-handle">
          <div className="login-container">
            <div className="login-form-container">
              <div className="login-form">
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "0rem", marginTop: "2rem", marginBottom: "2rem"}}>
                  <a href="/"><img src={logologin} className="h-[110px]" title="bitsker" alt="Logo login" /></a>
                </Box>
                {children}
              </div>
            </div>
            <div className="login-image-container">
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "0rem", marginTop: "5rem", marginBottom: "1rem"}} >
                <img src={logo} className="h-[50px]" title="Logo login" alt="Logo login" />
              </Box>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default LayoutLogin;