import React, { ReactElement, FC } from "react";
import { Box, Typography } from "@mui/material";
import {useTranslation} from "react-i18next";
import { AuthCheckRedirect } from "../services/auth.service";

const Products: FC<any> = (): ReactElement => {
  
  // Navigate redirect to /my if user is set
  AuthCheckRedirect();
  //

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h3">Products</Typography>
    </Box>
  );
};

export default Products;