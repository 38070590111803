import React from "react";
import "date-fns";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/nb';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker  } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker  } from '@mui/x-date-pickers/MobileDatePicker';
import { GetPeriod, SetPeriod } from "../../../services/period.service";


/*
const CoreMonthDatePicker: FC = (): ReactElement<any> => {

  const [selectedDate, setDate] = React.useState<Date | null>(new Date());

  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={noLocale}>
        <DatePicker
          views={["year", "month"]}
          //label="Year and Month"
          //helperText="With min and max"
          minDate={new Date("2022-01-01")}
          maxDate={new Date("2023-12-31")}
          value={selectedDate}
          onChange={setDate}
        />
      </MuiPickersUtilsProvider>
  );
}
*/






/*
class CoreMonthDatePicker extends React.Component<any> {
  onTrigger = (event: any) => {
    //console.log(event);
    //this.props.parentCallback(event);
    console.log(event?.getFullYear());
    this.props.parentCallback(event?.getFullYear());
    event.preventDefault();

}


render(){
    return(
    
      <Fragment>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardDatePicker
          autoOk={true}
          showTodayButton={true}
          value={selectedDate}
          format="YYYY-MM-DD"
          inputValue={inputValue}
          onChange={onDateChange}
          rifmFormatter={dateFormatter}
        />
      </MuiPickersUtilsProvider>
    </Fragment>
    
    
      /*
    <div>
        <form onChange = {this.onTrigger}>
            <input type = "text" 
            name = "myname" placeholder = "Enter Name"/>
        </form>
    </div>
    *//*

    )
}
}
*/


function CoreMonthDatePicker(props: any) {

  function onChangeHandler(date: any) {
      props.onChange(date);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
        <DatePicker 
          label={props.text} 
          views={['month', 'year']}
          minDate={dayjs('2023-01-01')}
          maxDate={dayjs('2024-12-31')}
          defaultValue={dayjs(props.date)}
          onChange={(newValue) => onChangeHandler(newValue)}
          //onChange={onChangeHandler}
        />
    </LocalizationProvider>
  );

  /*
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
      <DatePicker 
        label={props.text}
        views={['year', 'month']}
        minDate={dayjs('2023-01-01')}
        maxDate={dayjs('2024-12-31')}
        value={props.date}
        onChange={onChangeHandler}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
  */

}

function CoreMonthDatePicker2(props: any) {
    const [period, setValue] = React.useState<Dayjs | null>(null);
  
    /*
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Basic example"
          views={['year', 'month']}
          minDate={dayjs('2022-01-01')}
          maxDate={dayjs('2023-12-31')}
          value={period}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    );
    */
  }
export default CoreMonthDatePicker;