import React, { FC, ReactElement, useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import IUserTotalsTransactions, { ITransaction } from "../../../DataModels/Dashboard/UserTotalsTransactionsModel";
import CoreBorderBox from "../../Core/CoreBorderBox";
import CoreBorderBoxInner from "../../Core/CoreBorderBoxInner";
import DashboardElementUserTotalsTransactions from "../UserTotalsTransactions";
import { getAccountsData, getTransactionsData } from "../../../../services/totals.service";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CoreModalBasic } from "../../Core/CoreModalBasic";
import { CoreModalStatic } from "../../Core/CoreModalStatic";
import { GetPeriod } from "../../../../services/period.service";

export enum DashboardTransactionsModalIdEnum {
	ALL = "all",
	LOWHIGH = "lowhigh",
    HIGHLOW = "highlow",
    LAST = "last",
    PENDING = "pending"
}

export function DashboardTransactionsModal() {
    const { t, i18n } = useTranslation('common');

    // Input data
    const { id } = useParams();

    const allTransactions = id == DashboardTransactionsModalIdEnum.ALL;
    const lowHighTransactions = id == DashboardTransactionsModalIdEnum.LOWHIGH;
    const highLowTransactions = id == DashboardTransactionsModalIdEnum.HIGHLOW;
    const lastTransactions = id == DashboardTransactionsModalIdEnum.LAST;
    const pendingTransactions = id == DashboardTransactionsModalIdEnum.PENDING;

    // Transactions data
    const [userTotalsTransactions, setUserTotalsTransactions] = useState<IUserTotalsTransactions>();
    const [displayTransactions, setDisplayTransaction] = useState<Array<ITransaction>>();
    const [displayHeading, setDisplayHeading] = useState<string>();
    const [period, setPeriod] = useState<any>(GetPeriod());

    async function fetchTransactionsData() {
        const response = await getTransactionsData(period);
        const json = await response.data;
        setUserTotalsTransactions(json.data);
        setDisplayTransaction(json.data?.lastTransactions);
        setDisplayHeading(t('pages.my.dashboard.usertotals-transactions-transaction-last-heading'));
    }
    async function sleep(ms: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    useEffect(() => 
        {
            fetchTransactionsData();
        },
        []
    );
    const handleDisplayedTransactions = (choose:any) => {
        if (choose == DashboardTransactionsModalIdEnum.LAST || choose == DashboardTransactionsModalIdEnum.ALL)  {
            setDisplayTransaction(userTotalsTransactions?.lastTransactions);
            setDisplayHeading(t('pages.my.dashboard.usertotals-transactions-transaction-last-heading'));
        }
        if (choose == DashboardTransactionsModalIdEnum.HIGHLOW)  {
            setDisplayTransaction(userTotalsTransactions?.lastHighestTransactions);
            setDisplayHeading(t('pages.my.dashboard.usertotals-transactions-transaction-lasthighest-heading'));
        }
        if (choose == DashboardTransactionsModalIdEnum.LOWHIGH)  {
            setDisplayTransaction(userTotalsTransactions?.lastLowestTransactions);
            setDisplayHeading(t('pages.my.dashboard.usertotals-transactions-transaction-lastlowest-heading'));
        }
        if (choose == DashboardTransactionsModalIdEnum.PENDING)  {
            setDisplayTransaction(userTotalsTransactions?.pendingTransactions);
            setDisplayHeading(t('pages.my.dashboard.usertotals-transactions-transaction-pending-heading'));
        }
      };

    /*
    //const modalRef = useRef();
    const navigate = useNavigate();
    useEffect(() => {
        const observerRefValue :any = modalRef.current;
        disableBodyScroll(observerRefValue);
        return () => {
        if (observerRefValue) {
            enableBodyScroll(observerRefValue);
        }
        };
    }, []);
    */
    return (
        <CoreModalStatic closeUrl="/my/dashboard" subheader={t('pages.my.dashboard.usertotals-transactions-transaction-show-list-heading')}>
            <>
            <Button onClick={() => handleDisplayedTransactions(DashboardTransactionsModalIdEnum.LAST)}>Last</Button>

            <Button onClick={() => handleDisplayedTransactions(DashboardTransactionsModalIdEnum.HIGHLOW)}>High low</Button>
            
            <Button onClick={() => handleDisplayedTransactions(DashboardTransactionsModalIdEnum.LOWHIGH)}>Low high</Button>

            {//<Button onClick={() => handleDisplayedTransactions(DashboardTransactionsModalIdEnum.PENDING)}>pending</Button>
            }
            <Box>
                <CoreBorderBoxInner heading={displayHeading+ period}>
                    <DashboardElementUserTotalsTransactions transactions={displayTransactions} />
                </CoreBorderBoxInner>
            </Box>
            </>
        </CoreModalStatic>
    );
}