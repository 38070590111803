import { ReactElement, FC } from "react";
import { Box, Typography } from "@mui/material";
import {useTranslation} from "react-i18next";
import { AuthCheckRedirect } from "../services/auth.service";

const Contact: FC<any> = (): ReactElement => {
  const {t, i18n} = useTranslation('common');

   // Navigate redirect to /my if user is set
   AuthCheckRedirect();
   //

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "20px",
      }}
    >
      <Box maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
        </Typography>
        <Typography variant="h4" align="center" color="text.secondary" component="p" sx={{ paddingBottom: "40px", }}>
          {t('pages.contact.would-you-like')}
        </Typography>
        
        <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{ paddingBottom: "40px", }}>
          {t('pages.contact.or-just')}
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{ paddingBottom: "40px", }}>
          {t('pages.contact.email-us-at', {email:'hei@whybie.no'})}
        </Typography>
      </Box>
    </Box>
  );
};

export default Contact;