import React, { FC, ReactNode } from "react";
import { Box, CssBaseline } from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";

interface LayoutProps {
  children: ReactNode;
}

export const LayoutDefault: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          minHeight: "100vh",
          maxWidth: "100vw",
          flexGrow: 1,
        }}
      >
        <Navbar />
        <Box
          sx={{
            width: "100%",
            height: "auto",
            //backgroundColor: "#ffffff",//"secondary.main",
            minHeight: "100vh",
            maxWidth: "100vw",
            paddingLeft: { xs: "0px", md: "0px" },
            paddingRight: { xs: "0px", md: "0px" },
            paddingTop: { xs: "20px", md: "40px" },
          }}
        >
          <Box sx={{ textAlign: "left",  display: "block" }} >
            <Box sx={{ margin: "0 auto",  padding: "0 0px" }}>
              {children}
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default LayoutDefault;