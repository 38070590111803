import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import {useTranslation} from "react-i18next";
import { getTransactionsData, getCategoriesData } from "../../../services/budget.service";
import { IUserTransactionsData, ITransaction } from "../../../components/DataModels/Budget/UserBudgetModel";
import { CoreModalBasic } from "../../Elements/Core/CoreModalBasic";
import EditTransactionForm from "./EditTransactionForm";

interface Props {
    budgetAccountedLine: any,
    period: any,
}

export const ViewTransactionsList = ({...props}: Props) => {
    var budgetAccountedLine = props.budgetAccountedLine;
    var period = props.period;
    const {t, i18n} = useTranslation('common');
    const [userBudgetTransactions, setUserBudgetTransactions] = useState<IUserTransactionsData>();
    const [userBudgetCategories, setUserBudgetCategories] = useState<any>();

    useEffect(() => {
        new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(getTransactionsData(budgetAccountedLine.categoryId, period));
            }, 1000);
         }).then((value:any) => {
            setUserBudgetTransactions(value.data.data);
         });
    }, []);
    useEffect(() => {
        new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(getCategoriesData());
            }, 1000);
         }).then((value:any) => {
            setUserBudgetCategories(value.data.data);
         });
    }, []);

    return (
        <div className="">
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: 'auto',
                    p: 0,
                }}
            >
                {userBudgetTransactions?.transactions?.map((budgetAccountedLine: ITransaction, idx: any) => (
                    <Box key={idx}
                    sx={{
                        display: "grid",
                        gridTemplateColumns: 'auto',
                        pb: 1,
                    }}
                >
                    <div>{budgetAccountedLine.originalDescription}</div>
                    <div>{budgetAccountedLine.amount}</div>
                    <div>
                        <CoreModalBasic parentCallback={() =>{}} header={t('pages.my.budget.modal.edit-accounting-transaction')} subheader={budgetAccountedLine.originalDescription}>
                            <EditTransactionForm transaction={budgetAccountedLine} categories={userBudgetCategories} />
                        </CoreModalBasic>
                    </div>
                </Box>
                ))}
            </Box>
        </div>
    );
}

