import React, { ReactElement, FC, useState, useEffect, useCallback } from "react";
import { getStatusData, getSyncDataAccountsData, getSyncDataTransactionsData, getSyncDataProcessingData } from "../../services/user.service";
import {useTranslation} from "react-i18next";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { getCurrentUser } from "../../services/auth.service";
import { IStatus, IUserSyncDataAccounts, IUserSyncDataTransactions, IUserSyncDataProcessing } from "../../components/DataModels/Dashboard/UserInitiateModel";
import IntegrationInitiate from "../../components/Elements/Dashboard/IntegrationInitiate";
import { DashboardAccounts } from "../../components/Layout/Dashboard/DashboardAccounts";
import { getStatus } from "../../services/user.service";
import { StatusModel } from "../../components/DataModels/Dashboard/UserInitiateModel";
import { CoreCircularWithValueLabel } from "../../components/Elements/Core/CoreCircularProgressWithLabel ";

const Dashboard: FC<any> = (): ReactElement => {
    const {t, i18n} = useTranslation('common');
    const currentUser = getCurrentUser();
    let navigate: NavigateFunction = useNavigate();
    if (currentUser == null) {
      navigate("/login");
    }
    const [userStatus, setUserStatus] = useState<IStatus>(getStatus());
    const [syncAccountsIsActive, setSyncAccountsIsActive] = useState(false);
    const [syncTransactionsIsActive, setSyncTransactionsIsActive] = useState(false);
    const [syncProcessingIsActive, setSyncProcessingIsActive] = useState(false);
    const [isInitiateLoading, setIsInitiateLoading] = useState(false);

    
    async function sleep(ms: number): Promise<void> {
      return new Promise(
          (resolve) => setTimeout(resolve, ms));
    }

    function updateData() {
      localStorage.setItem("status", JSON.stringify(userStatus));
      let ui = JSON.parse(JSON.stringify(userStatus))
      let newUi = new StatusModel(ui)
      setUserStatus(newUi);
    }
    
    // Functions
    function fetchInitiateData() {
      setIsInitiateLoading(true);
      getStatusData().then(
        (response) => { 
          var json = response.data;
          userStatus.SetInitiated();
          if(json.hasIntegrated) {
            userStatus.SetHasIntegrated();
          }
          if(json.hasCompleted) {
            userStatus.SetHasCompleted();
          }
          if(json.hasAccountingLines) {
            userStatus.SetHasAccountingLines();
          }
          updateData();
          setIsInitiateLoading(false);
        },
        (error) => {
          setIsInitiateLoading(false);
        }
      );
    }

    function fetchSyncDataAccountsData() {
      getSyncDataAccountsData().then(
        (response) => { 
          var json = response.data;
          userStatus.SetSyncDataAccounts();
          updateData();
          setSyncAccountsIsActive(false);
        },
        (error) => {
          console.log("Error syncDataAccounts. ")
          sleep(2000);
          updateData();
          setSyncAccountsIsActive(false);
        }
      );
    }

    function fetchSyncDataTransactionsData() {
      getSyncDataTransactionsData().then(
        (response) => { 
          var json = response.data;
          userStatus.setSyncDataTransactions();
          updateData();
          setSyncTransactionsIsActive(false);
        },
        (error) => {
          console.log("Error syncDataTransactions. ")
          sleep(2000);
          updateData();
          setSyncTransactionsIsActive(false);
        }
      );
    }
    
    function fetchSyncDataProcessingData() {
      getSyncDataProcessingData().then(
        (response) => { 
          var json = response.data;
          userStatus.SetLoaded();
          userStatus.setSyncDataProcessing();
          sleep(3000);
          updateData();
          setSyncProcessingIsActive(false);
        },
        (error) => {
          console.log("Error syncDataProcessing. ")
          sleep(3000);
          updateData();
          setSyncProcessingIsActive(false);
        }
      );
    }

    // Initiate Status Data
    useEffect(function effectFunction() {
      if(!userStatus.IsInitiated) {
        fetchInitiateData();
      }
    }, [userStatus]);
   

    // Sync Data Accounts
    useEffect(function effectFunction() {
      if ( userStatus.IsInitiated && userStatus.IsIntegrated && !userStatus.IsSyncDataAccounts && !syncAccountsIsActive) { //!userStatus.IsLoaded &&
        setSyncAccountsIsActive(true);
        fetchSyncDataAccountsData();
      }
    }, [userStatus]);

    // Sync Data Transactions
    useEffect(function effectFunction() {
      if (userStatus.IsInitiated && userStatus.IsIntegrated && !userStatus.IsSyncDataTransactions && !syncTransactionsIsActive ) { // !userStatus.IsLoaded && 
        setSyncTransactionsIsActive(true);
        fetchSyncDataTransactionsData();
      }
    }, [userStatus]);

    // Sync Data Processing
    useEffect(function effectFunction() {
      if (userStatus.IsInitiated && userStatus.IsSyncDataTransactions && !userStatus.IsSyncDataProcessing && !syncProcessingIsActive) { //!userStatus.IsLoaded && 
        setSyncProcessingIsActive(true);
        fetchSyncDataProcessingData();
      }
    }, [userStatus]);
    
    if(isInitiateLoading) {
      return (
        <></>
      );
    }

    if(!userStatus.IsInitiated || userStatus.IsSyncDataProcessing != true || userStatus.IsSyncDataProcessing != true) {
        return (
          <>
            <div
              style={{
                  textAlign: 'center',
                  position: 'absolute', 
                  left: '50%', 
                  top: '30%',
                  transform: 'translate(-50%, -50%)'
              }}
            >
              <div>
                <p>...loading</p>
                <p>{t('pages.my.dashboard.loading-description')}</p>
              </div>
              <CoreCircularWithValueLabel timeLengthMilliSeconds={1200} />
            </div>
          </>
        );
    }
    
    if (userStatus.hasIntegrated) {
      return  ( <>
        {userStatus.IsSyncDataAccounts && userStatus.IsSyncDataProcessing && (<DashboardAccounts /> )}
        </>)
    }

    return (
      <IntegrationInitiate />
    );
};

export default Dashboard;