import React, { FC, ReactElement, useState, useEffect, useCallback } from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import {useTranslation} from "react-i18next";
import { getSummaryData } from "../../../services/totals.service";
import EventBus from "../../../services/EventBus";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getCurrentUser } from "../../../services/auth.service";
import { DashboardElementUserTotalsFixedExpenses } from "./UserTotalsFixedExpenses";
import { DashboardElementUserTotalsVariableExpenses } from "./UserTotalsVariableExpenses";
import IUserTotals from "../../DataModels/Dashboard/UserTotalsModel";

interface CustomGridProps {
    title:any, 
    titleBold:Boolean, 
    leftValue:any, 
    rightValue:any, 
    leftColor:any, 
    rightColor:any 
}

const CustomGridContainerData = ({...props}:CustomGridProps) => {
    let fontWeight = props.titleBold ? '800' : '300';
    return (
        <Grid container spacing={{ xs: 0.1, md: 0.1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs={6} md={8} >
                <div>
                    <div style={{fontWeight: fontWeight, float:"left"}}>{props.title}</div>
                </div>
            </Grid>
            <Grid item xs={6} md={4} >
                <Grid container spacing={{ xs: 0.1, md: 0.1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                    <Grid item xs={6} md={5} >
                        <div style={{ fontWeight: fontWeight, color: props.leftColor }}>{props.leftValue}</div>
                    </Grid>
                    <Grid item xs={6} md={7} >
                        <div style={{fontWeight: fontWeight, color: props.rightColor, float: "left"}}>{props.rightValue}</div> 
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const DashboardElementUserTotalsSummary = ({period}: any) => {
    const {t, i18n} = useTranslation('common');
    const [userTotalsSummary, setUserTotalsSummary] = useState<IUserTotals>();
    async function fetchData() {
        const response = await getSummaryData(period);
        const json = await response.data;
        setUserTotalsSummary(json.data);
    }
    useEffect(() => {
        fetchData();
    }, [period]);

    return (
        <div className="">
            <CustomGridContainerData 
                title={t('pages.my.dashboard.this-period-heading')}
                titleBold={true}
                leftValue={"Brukt"}
                leftColor={"black"}
                rightValue={"/ Budsjett"}
                rightColor={"black"}
            />
            <CustomGridContainerData 
                title={t('pages.my.dashboard.budget-this-period-heading')}
                titleBold={false}
                leftValue={Math.round(userTotalsSummary?.spentAmount)}
                leftColor={(userTotalsSummary?.spentAmount > userTotalsSummary?.budgetAmount) ? "red" : "green"}
                rightValue={"/ " + Math.round(userTotalsSummary?.budgetAmount)}
                rightColor={"black"}
            />
            <CustomGridContainerData 
                title={t('pages.my.dashboard.fixed-expenses-heading')}
                titleBold={false}
                leftValue={Math.round(userTotalsSummary?.fixedExpensesSpentAmount)}
                leftColor={(userTotalsSummary?.fixedExpensesSpentAmount > userTotalsSummary?.fixedExpensesBudgetAmount) ? "red" : "green"}
                rightValue={"/ " + Math.round(userTotalsSummary?.fixedExpensesBudgetAmount)}
                rightColor={"black"}
            />
            <CustomGridContainerData 
                title={t('pages.my.dashboard.variable-expenses-heading')}
                titleBold={false}
                leftValue={Math.round(userTotalsSummary?.variableExpensesSpentAmount)}
                leftColor={(userTotalsSummary?.variableExpensesSpentAmount > userTotalsSummary?.variableExpensesBudgetAmount) ? "red" : "green"}
                rightValue={"/ " + Math.round(userTotalsSummary?.variableExpensesBudgetAmount)}
                rightColor={"black"}
            />
            

            <CustomGridContainerData 
                title={t('pages.my.dashboard.spent-on-groceries-this-period-heading')}
                titleBold={false}
                leftValue={Math.round(userTotalsSummary?.spentOnGroceriesMonthAmount)}
                leftColor={"black"}
                rightValue={""}
                rightColor={"black"}
            />
            <CustomGridContainerData 
                title={t('pages.my.dashboard.this-week-heading')}
                titleBold={true}
                leftValue={""}
                leftColor={"black"}
                rightValue={""}
                rightColor={"black"}
            />
            <CustomGridContainerData 
                title={t('pages.my.dashboard.spent-on-groceries-this-week-heading')}
                titleBold={false}
                leftValue={Math.round(userTotalsSummary?.spentOnGroceriesWeekAmount)}
                leftColor={"black"}
                rightValue={""}
                rightColor={"black"}
            />

        </div>
    );
}

export default DashboardElementUserTotalsSummary;
