import React, { FC, ReactElement, useState, useEffect, useCallback } from "react";
import moment from "moment";

export const GetPeriod = () => {
    
    const getPeriod = () => {
        var dateString = localStorage.getItem("period");
        if (dateString == null) {
          return moment().format("YYYY-MM-DD");
          //return period;// React.useState(moment());
        }
        var date = moment(dateString, 'YYYY-MM-DD');
        return date.format("YYYY-MM-DD");
      }
      //const [period, setPeriod] = useState<any>(GetPeriod());
    //return period;
    return getPeriod();
    

}

export const SetPeriod = (date: any) => {
    
        //const [period, setPeriod] = useState<any>(GetPeriod());
        
        const periodHandler = (date: any) => {
            //setPeriod(date.format("YYYY-MM-DD"));
            let period = date.format("YYYY-MM-DD");
            localStorage.setItem("period", period);

        };

        periodHandler(date);

    //return period;
    
}