import React, { FC, ReactElement, useState, useEffect, useCallback, useRef } from "react";
import { Modal, Button as ReactBootstrapButton } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IUserTotalsAccounts, { IAccount } from "../../../DataModels/Dashboard/UserTotalsAccountsModel";
import IUserTotalsTransactions, { ITransaction } from "../../../DataModels/Dashboard/UserTotalsTransactionsModel";
import Grid from '@mui/material/Grid';
import CoreBorderBox from "../../Core/CoreBorderBox";
import CoreBorderBoxInner from "../../Core/CoreBorderBoxInner";
import DashboardElementUserTotalsTransactions from "../UserTotalsTransactions";
import { getAccountsData, getTransactionsData } from "../../../../services/totals.service";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CoreModalBasic } from "../../Core/CoreModalBasic";
import { CoreModalStatic } from "../../Core/CoreModalStatic";
import { GetPeriod } from "../../../../services/period.service";
import { deleteBankingAccount } from "../../../../services/banking.service";
import { cancelIntegration, CancelIntegrationResponse } from "../../../../services/user.service";
import { CoreAlertDialogConfirm } from "../../Core/CoreAlertDialogConfirm";

export enum DashboardAccountsModalIdEnum {
	EDIT = "edit",
	DELETE = "delete"
}

function DeleteConfirmModal() {
    const { t, i18n } = useTranslation('common');

    return (
        <>
        
        </>
    )
}

export function DashboardAccountsSettingsModal() {
    const { t, i18n } = useTranslation('common');

    // Input data
    const { id } = useParams();

    // Transactions data
    const [userTotalsTransactions, setUserTotalsTransactions] = useState<IUserTotalsTransactions>();
    const [period, setPeriod] = useState<any>(null);

    
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);

    

    

    // Delete confirm modal settings
    const [deleteMessage, setDeleteMessage] = useState("");
    const [deleteAccount, setDeleteAccount] = useState();
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

    const showDeleteModal = (account:any) => {

        setDeleteAccount(account);
        setDeleteMessage(t('pages.my.dashboard.usertotals-transactions-account-delete-are-you-sure'));
        setShowDeleteConfirmModal(true);

    };




    // Cancel integration

      
  const [cancelIntegrationResponse, setCancelIntegrationResponse] = useState<CancelIntegrationResponse>();
  const [cancelMessage, setCancelMessage] = useState("");
  const [loadingCancelintegration, setLoadingCancelIntegration] = useState<Boolean>(false);
  const handleCancelIntegration = () => {
    // request
    setLoadingCancelIntegration(true);
    cancelIntegration().then(
      (response:CancelIntegrationResponse) => {
        setCancelIntegrationResponse(response);
        setCancelMessage(t('pages.my.dashboard.premium-subscription-is-cancelled'));
      },
      (error) => {
      }
    );
    // request done
  };
  
  

    // end

    const returnDefault = () => {
        return(
        <>
        <div className="">
            
            <div className="row" key="cancel">
                <div className="col-md-12 col-sm pb10">
                    <div className="row">
                        <div className="col-md-8 col-sm">
                            <p>{t('pages.my.checkout.premium-subscription-description-2')}</p>
                            <CoreAlertDialogConfirm header={t('pages.my.checkout.button-confirm-cancel-subscription')} contentText="" buttonText={t('pages.my.checkout.button-cancel-subscription')}  handleConfirm={handleCancelIntegration}>
                                <p>{t('pages.my.checkout.premium-subscription-cancel-confirm-description')}</p>
                            </CoreAlertDialogConfirm>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        )
    }

    
    return (
        <>

        {!showDeleteConfirmModal && (
            <CoreModalStatic closeUrl="/my/dashboard" subheader={t('pages.my.dashboard.usertotals-transactions-account-accounts')}>
                <>
                <Box>
                {!loading && (returnDefault()) }
                </Box>
                </>
            </CoreModalStatic>
        )}
        </>
    );
}