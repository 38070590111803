import { logout } from "./auth.service";
import { NavigateFunction, useNavigate } from 'react-router-dom';


const eventBus = {
  
    on(event: string, callback: EventListener) {
      document.addEventListener(event, (e) => callback(e));
    },
    dispatch(event: string, data?: any) {
      document.dispatchEvent(new CustomEvent(event, { detail: data }));
      if(event == "logout") {
       
        logout();
        window.location.reload();
      }
    },
    remove(event: string, callback: EventListener) {
      document.removeEventListener(event, callback);
    },
  };
  
  export default eventBus;
  