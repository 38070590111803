export interface IUser {
  firstname?: string,
  lastname?: string,
  username: string,
  role: string,
  token: string,
  isAuthenticated: boolean
}

const ROLE_GOLD = "GOLD";
const ROLE_PREMIUM = "PREMIUM";
const ROLE_BASIC = "BASIC";
const ROLE_FREE = "FREE"; // depricated

export class User<IUser> {
  public firstname?: string;
  public lastname?: string;
  public username: string;
  public role: string;
  public token: string;
  public isAuthenticated: boolean;


  constructor(props : any) {
    this.firstname = props?.firstname ?? '';
    this.lastname = props?.lastname ?? '';
    this.username = props?.username;
    this.role = props?.role;
    this.token = props?.token;
    this.isAuthenticated = props?.isAuthenticated;
  }

  public get isFree(): boolean { // depricated
    return this.role.includes(ROLE_FREE);
  }

  public get isBasic(): boolean { // depricated
    return this.role.includes(ROLE_BASIC);
  }

  public get isPremium(): boolean {
    return this.role.includes(ROLE_PREMIUM);
  }

  public get isGold(): boolean {
    return this.role.includes(ROLE_GOLD);
  }

  
}
