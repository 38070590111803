
import { useState } from "react";
import { Typography, TextField, Paper } from "@mui/material";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {useTranslation} from "react-i18next";
import './LoginForm.scss'

//import React, { useState } from "react";
import { NavigateFunction, useNavigate, useLocation } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { signup } from "../../../services/auth.service";
import { getInitialValues, validationSchema } from './LoginFormUtils'

type Props = {}

//const LoginForm: FC<any> = (): JSX.Element => {
export const SignupForm = (props: Props): JSX.Element => {
  const {t, i18n} = useTranslation('common');
  let navigate: NavigateFunction = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  /*
  const initialValues = () => ({
    username: ''
  })
  */

  const initialValues2: {
    username: string;
    password: string;
  } = {
    username: "",
    password: "",
  };
  
  const validationSchema = Yup.object().shape({
    //username: Yup.string().required(t('pages.login.input-label-errror-required')),
    //password: Yup.string().required(t('pages.login.input-label-errror-required')),
  });
  
  const search = useLocation().search;
  var membership:any = new URLSearchParams(search).get('membership');
  //const handleLogin = (formValue: { username: string; password: string }) => {
  const onFormSubmit = () => {
    setUsername('');
    setPassword('');
    setPasswordConfirm('');
    //const { username, password } = formValue;

    setMessage("");
    setLoading(true);

    signup(username, password, passwordConfirm, membership).then(
      () => {
        navigate("/my/dashboard");
        window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          (error.response &&
            error.response.data) ||
          error.message ||
          error.toString()
        ;
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const [usernameValue, setUsernameValue] = 
  useState("");

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  

  return (
    <div>
    <Paper
    variant="elevation"
    elevation={0}
    className="login-background1"
    >
    <Grid item>
    <Typography variant="h5" align="center" color="text.secondary" className="login-header" component="p" sx={{ padding: "0px 0 20px 0", }}>
        {t('pages.signup.header-signup', {framework:'React'})}
    </Typography>
    </Grid>
    <Grid item>
        {/*<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleLogin} >*/}
        <Formik
                initialValues={getInitialValues()}
                validationSchema={validationSchema}
                onSubmit={onFormSubmit}>
            <Form>
                <Grid container direction="column" spacing={2} textAlign={("center")}>
                <Grid item textAlign={("center")}>
                  <TextField
                      type="email"
                      name="username"
                      label={t('pages.login.input-label-username')}
                      onChange={(event) =>
                        setUsername(event.target.value)
                      }
                      value={username}
                      required
                  />
                  <ErrorMessage
                      name="username"
                      component="div"
                      className="alert alert-danger"
                  />
                </Grid>

                <Grid item textAlign={("center")}>
                  <TextField
                      type="password"
                      name="password"
                      label={t('pages.login.input-label-password')}
                      onChange={(event) =>
                        setPassword(event.target.value)
                      }
                      value={password}
                      required
                  />
                  <ErrorMessage
                      name="password"
                      component="div"
                      className="alert alert-danger"
                  />
                </Grid>
                

                <Grid item textAlign={("center")}>
                  <TextField
                      type="password"
                      name="passwordConfirm"
                      label={t('pages.login.input-label-passwordConfirm')}
                      onChange={(event) =>
                        setPasswordConfirm(event.target.value)
                      }
                      value={passwordConfirm}
                      required
                  />
                  <ErrorMessage
                      name="passwordConfirm"
                      component="div"
                      className="alert alert-danger"
                  />
                </Grid>

                <Grid item textAlign={("center")}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="button-block"
                    disabled={loading}
                >
                    {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>{t('pages.signup.button-signup')}</span>
                </Button>
                </Grid>
                    {message && (
                        <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {message}
                        </div>
                        </div>
                    )}
                </Grid>
            </Form>
        </Formik>
        </Grid>
    </Paper>
    <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{ paddingTop: "10px" }}>
          <Link href="/login/LostPassword" variant="subtitle1" color="#00000"
          sx={{ 
          font: "Roboto !important",
          fontWeight: "400",
          textDecoration: "none",
          fontSize: "1.1rem",
          color: "rgba(0, 0, 0, 0.6)"
          }}
          >
            {t('pages.login.forgot-password', {framework:'React'})}
          </Link>
        </Typography>
        
    </div>
  );
};

export default SignupForm;