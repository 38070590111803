import React, {  ReactElement, FC, useCallback, useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { Box, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import CoreBorderBox from "../../components/Elements/Core/CoreBorderBox";
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUser } from "../../services/auth.service";
import * as AuthService from "../../services/auth.service";
import { getSubscriptionStatus } from "../../services/checkout.service";
import { cancelSubscription, createCheckoutSession, CreateCheckoutSessionResponse, CancelSubscriptionResponse, Subscription } from "../../services/checkout.service";
import { APP_API_URL, APP_API_URL_DEV } from "../../Config/Constants";
import { CoreAlertDialogConfirm } from "../../components/Elements/Core/CoreAlertDialogConfirm";
import Link from '@mui/material/Link';


var apiUrl = window.location.hostname.includes('localhost') ? APP_API_URL_DEV : APP_API_URL;
const API_URL = apiUrl+"/checkout/create-checkout-session";

const ProductDisplay = () => (
  <section>
    <div className="product">
      <img
        src="https://i.imgur.com/EHyR2nP.png"
        alt="The cover of Stubborn Attachments"
      />
      <div className="description">
      <h3>Stubborn Attachments</h3>
      <h5>$20.00</h5>
      </div>
    </div>
    <form action={API_URL} method="POST">
      <button type="submit">
        Checkout
      </button>
    </form>
  </section>
);

const CheckoutSessionRedirect = (): JSX.Element => {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [redirecturl, setRedirectUrl] = useState("");
  const {t, i18n} = useTranslation('common');
  let navigate: NavigateFunction = useNavigate();

  const handleCreateCheckoutSession = () => {
    setIsLoggedIn(true);
    let product:string = "Premium"
    // request
    createCheckoutSession(product).then(
      (response:CreateCheckoutSessionResponse) => {
        setRedirectUrl(response.url);
        window.location.href = response.url;
      },
      (error) => {
      }
    );
    // request done
  };
  
  const [cancelSubscriptionResponse, setCancelSubscriptionResponse] = useState<CancelSubscriptionResponse>();
  const handleCancelSubscription = () => {
    // request
    setLoading(true);
    cancelSubscription().then(
      (response:CancelSubscriptionResponse) => {
        setCancelSubscriptionResponse(response);
        fetchData();
        setCancelMessage(t('pages.my.checkout.premium-subscription-is-cancelled'));
      },
      (error) => {
      }
    );
    // request done
  };
  const [loading, setLoading] = useState<Boolean>(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState<Subscription>();
  const [cancelMessage, setCancelMessage] = useState("");

  async function fetchData() {
      const response = await getSubscriptionStatus();
      const json = await response.data;
      setSubscriptionStatus(json.data);
      setLoading(false);
  }
  
  useEffect(() => {
      fetchData();
  }, []);


  if (loading) {
    return (<>loading...</>);
  }
  if (cancelMessage) {
    return (
      <section><div>
        <div>
          <p>{cancelMessage}</p>
          <p>{t('pages.my.checkout.premium-subscription-is-cancelled-2')}</p>
        </div>
        </div></section>
    );
  }
  return subscriptionStatus != null  ?  
  (
    <section>
      <div>
        <div>
          <h5>{t('pages.my.checkout.premium-subscription-header')}</h5>
          <p>{t('pages.my.checkout.premium-subscription-description')}</p>
          <p>{t('pages.my.checkout.premium-subscription-description-2')}</p>
          <CoreAlertDialogConfirm header={t('pages.my.checkout.button-confirm-cancel-subscription')} contentText="" buttonText={t('pages.my.checkout.button-cancel-subscription')}  handleConfirm={handleCancelSubscription}>
            <p>{t('pages.my.checkout.premium-subscription-cancel-confirm-description')}</p>
          </CoreAlertDialogConfirm>
        </div>
      </div>
    </section>
  ) :
  (
    <section>
      <div>
        <div>
          <h5>{t('pages.my.checkout.basic-subscription-header')}</h5>
          <p>{t('pages.my.checkout.basic-subscription-description')}</p>
          <p>{t('pages.my.checkout.basic-subscription-description-2')}</p>
          <Button variant="outlined" sx={{ my: 1, mx: 1.5 }} onClick={handleCreateCheckoutSession}>
            {t('pages.my.checkout.button-checkout-premium')}
          </Button>
        </div>
      </div>
    </section>
  )

};

const Message = ({ messageHeading, message, buttonText }:any) => (
  <section>
    <h3>{messageHeading}</h3>
    <p>{message}</p>
    <Link href="/my/setting" variant="subtitle1" color="#00000"
          >
            <Button variant="outlined" sx={{ my: 1, mx: 1.5 }}>{buttonText}</Button>
      </Link>
    
  </section>
);

 function CheckoutForm() {
  const [messageHeading, setMessageHeading] = useState("");
  const [message, setMessage] = useState("");
  const [buttonText, setButtonText] = useState("");
  
  const {t, i18n} = useTranslation('common');

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessageHeading(t('pages.my.checkout.create-subscription-success-message-heading'));
      setMessage(t('pages.my.checkout.create-subscription-success-message'));
      setButtonText(t('pages.my.checkout.create-subscription-success-button-text'));
    }

    if (query.get("cancelled")) {
      setMessageHeading(t('pages.my.checkout.create-subscription-error-message-heading'));
      setMessage(t('pages.my.checkout.create-subscription-error-message'));
      setButtonText(t('pages.my.checkout.create-subscription-error-button-text'));
    }
  }, []);

  return message ? (
    <Message messageHeading={messageHeading} message={message} buttonText={buttonText} />
  ) : (
    <CheckoutSessionRedirect />
  );
}




export const Checkout: FC<any> = (): ReactElement => {
  const {t, i18n} = useTranslation('common');
  const currentUser = getCurrentUser();
  let navigate: NavigateFunction = useNavigate();

  if (currentUser == null) {
    return (<Box></Box>);
  }
  
  return (
    <Box>
      <CoreBorderBox>
        <Typography component="p" variant="h5" color="text.secondary">
          {t('pages.my.checkout.heading', {framework:'React'})}
        </Typography>
      </CoreBorderBox>

      <CoreBorderBox>
       
        <div className="container">
          
          <div className="ml-auto">
            <p>
            <strong>{t('pages.my.checkout.description')}</strong>
            </p>
            <CheckoutForm />
          </div>
          
          
        </div>
      </CoreBorderBox> 
    </Box>
  );
};
