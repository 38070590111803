import { FC, ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import {useTranslation} from "react-i18next";
import CoreBorderBox from "../Core/CoreBorderBox";
import CoreBorderBoxInner from "../Core/CoreBorderBoxInner";

import { CoreModalWide } from "../Core/CoreModalWide";


export const IntegrationInitiate: FC = (): ReactElement => {
    const {t, i18n} = useTranslation('common');
    return (
      <Box>
        <CoreBorderBox>
          <Typography component="p" variant="h5" color="text.secondary">
            {t('pages.my.dashboard.welcome')} 
              
          </Typography>
          
        </CoreBorderBox>
        <CoreBorderBox>
            <CoreBorderBoxInner width="100%">
              <Typography component="p" variant="h6" color="text.secondary">
                {t('pages.my.dashboard.oauth-heading')}
              </Typography>
              <Typography component="p" color="text.secondary">
                  {t('pages.my.dashboard.oauth-description')}
              </Typography>
              <p>
                <a href="/my/oauth">
                {t('pages.my.dashboard.oauth-connect-your-account')} 
                </a>
              </p>
              
            </CoreBorderBoxInner>
        </CoreBorderBox>
      </Box>
    );
  };
  
  export default IntegrationInitiate;