import React, { ReactElement, FC, useState, useEffect, useCallback } from "react";
import {useTranslation} from "react-i18next";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { fieldToSelect, SelectProps} from 'formik-mui';


export function CoreMuiSelect(props: any) { // SelectProps
  
  const {t, i18n} = useTranslation('common');
    const {
      form: {setFieldValue},
      field: {name},
    } = props;
    const [selectValue, setSelectValue] = useState<any>();
    const [fieldName, setFieldName] = useState<any>();
    const [fieldLabel, setFieldLabel] = useState<any>();
    const [selectValues, setSelectValues] = useState<Array<{value:any,text:any}>>([]);
    async function fetchData() {
      setSelectValues(props?.options ?? []);
    }
    useEffect(() => {
      setSelectValue(props.value);
      setFieldName(props.field?.name ?? "");
      setFieldLabel(props.label ?? "");
        fetchData();
    }, []);


    
    const handleChange = (event: SelectChangeEvent) => {
      setFieldValue(fieldName as string, event.target.value as string);
      setSelectValue(event.target.value);
    };
  
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id={fieldLabel}>{fieldLabel}</InputLabel>
          <Select
            id={fieldLabel}
            value={selectValue??""}
            label={fieldLabel}
            onChange={handleChange}
          >
            <MenuItem value="">{t('core.forms.select-select')}</MenuItem>
              {selectValues.length > 0 && (selectValues.map((selectValue:any) => (
                  <MenuItem key={(selectValue.value)} value={(selectValue.value)}>{(selectValue.text)}</MenuItem>
              )))}
          </Select>
        </FormControl>
      </Box>
    );
  }

export default CoreMuiSelect;