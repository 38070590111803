import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './App.css';
/*import './components/Layout/style.scss'*/
import 'bootstrap/dist/css/bootstrap.css';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import en from "./translations/en.json";
import no from "./translations/no.json";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'no',                              // language to use
  resources: {
      en: {
          common: en
      },
      no: {
          common: no
      },
  },
});

ReactDOM.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18next}>
          <App/>
      </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

