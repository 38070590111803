import { ReactElement, FC } from "react";
import { Box, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import { COLOR_PINK_LIGHT_BG, COLOR_WHITE } from "../Config/Constants";
import { iconarrowleft, iconarrowright, illustration_relax_home } from "../assets";
import { AuthCheckRedirect } from "../services/auth.service";

export const How: FC<any> = (): ReactElement => {
  const {t, i18n} = useTranslation('common');
  
  // Navigate redirect to /my if user is set
  AuthCheckRedirect();
  //
  
  return (
    <>
      
      {/* Field 1 */}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: COLOR_WHITE,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { xs: "20px", md: "220px" },
          paddingBottom: { xs: "40px", md: "310px" }
        }}
      >
        <div className="container">
          <div className="row">
            
            <div className="col-sm col-lg-6 col-md-12  align-self-center content-block-text">
              <Box component="main" sx={{ pt: {xs: "50px", md: "0px"}, pb: 0, pl: {xs: "0px", md: "220px"}}}>
                <Typography align="left" color="text.secondary" component="p" sx={{ fontSize:{ xs: "22px", md: "22px" }}}>
                    {t('pages.how.title', {framework:'React'})}
                  </Typography>
                  <Typography align="left" color="secondary.main" component="p" sx={{ paddingBottom: "20px", }}>
                    {t('pages.how.content', {framework:'React'})}
                  </Typography>
              </Box>
              <div className="d-lg-none d-sm-block float-end col-sm col-lg-6 col-md-12 image-block align-self-center">
                <div className="pl-20">
                  <img src={iconarrowright} className="h-[50px]" alt="" />
                </div>
              </div>
            </div>
            <div className="d-none d-lg-block col-sm col-lg-6 col-md-12 image-block align-self-center">
              <div className="pl-20">
                <img src={iconarrowright} className="h-[170px]" alt="" />
              </div>
            </div>

          </div>
        </div>
      </Box>
      {/* Field 1 end */}

      {/* Field 2 */}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: COLOR_PINK_LIGHT_BG,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { xs: "20px", md: "90px" },
          paddingBottom: { xs: "40px", md: "90px" }
        }}
      >
        <div className="container">
          <div className="row">
            
            <div className="d-none d-lg-block col-sm col-lg-6 col-md-12 image-block align-self-center">
                <div className="float-lg-end float-xl-end">
                  <img src={iconarrowleft} className="h-[90px]" alt="" />
                </div>
            </div>

            <div className="col-sm col-lg-6 col-md-12  align-self-center content-block-text">
              <Box component="main" sx={{  pt: 0, pb: 0, pl: {xs: "0px", md: "0px"}}}>
                <Typography align="left" color="text.secondary" component="p" sx={{fontSize:{ xs: "22px", md: "22px" }, paddingBottom: "20px", }}>
                  {t('pages.home.stress-free', {framework:'React'})}
                </Typography>
                <Typography align="left" color="secondary.main" component="p" sx={{ paddingBottom: "60px", }}>
                  {t('pages.home.try-it-out', {framework:'React'})}
                </Typography>
              </Box>
              <div className="d-lg-none d-sm-block float-start col-sm col-lg-6 col-md-12 image-block align-self-center">
                <div className="pl-20">
                  <img src={iconarrowleft} className="h-[50px]" alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </Box>
      {/* Field 2 end */}

      
      {/* Field 3 */}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: COLOR_WHITE,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { xs: "20px", md: "200px" },
          paddingBottom: { xs: "40px", md: "310px" }
        }}
      >
        <div className="container">
          <div className="row">
            
            <div className="col-sm col-lg-6 col-md-12  align-self-center content-block-text">
              <Box component="main" sx={{ pt: {xs: "50px", md: "0px"}, pb: 0, pl: {xs: "0px", md: "220px"}}}>
                <Typography align="left" color="text.secondary" component="p" sx={{ fontSize:{ xs: "22px", md: "22px" }}}>
                    {t('pages.how.step1-title', {framework:'React'})}
                  </Typography>
                  <Typography align="left" color="secondary.main" component="p" sx={{ paddingBottom: "20px", }}>
                    {t('pages.how.step1-content', {framework:'React'})}
                  </Typography>
              </Box>
              <div className="d-lg-none d-sm-block float-end col-sm col-lg-6 col-md-12 image-block align-self-center">
                <div className="pl-20">
                  <img src={iconarrowright} className="h-[50px]" alt="" />
                </div>
              </div>
            </div>
            <div className="d-none d-lg-block col-sm col-lg-6 col-md-12 image-block align-self-center">
              <div className="pl-20">
                <img src={iconarrowright} className="h-[90px]" alt="" />
              </div>
            </div>

          </div>
        </div>
      </Box>
      {/* Field 3 end */}

      {/* Field 4 */}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: COLOR_PINK_LIGHT_BG,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { xs: "20px", md: "90px" },
          paddingBottom: { xs: "40px", md: "90px" }
        }}
      >
        <div className="container">
          <div className="row">
            
            <div className="d-none d-lg-block col-sm col-lg-6 col-md-12 image-block align-self-center">
                <div className="float-lg-end float-xl-end">
                  <img src={iconarrowleft} className="h-[110px]" alt="" />
                </div>
            </div>

            <div className="col-sm col-lg-6 col-md-12  align-self-center content-block-text">
              <Box component="main" sx={{  pt: 0, pb: 0, pl: {xs: "0px", md: "0px"}}}>
                <Typography align="left" color="text.secondary" component="p" sx={{fontSize:{ xs: "22px", md: "22px" }, paddingBottom: "20px", }}>
                  {t('pages.how.step2-title', {framework:'React'})}
                </Typography>
                <Typography align="left" color="secondary.main" component="p" sx={{ paddingBottom: "60px", }}>
                  {t('pages.how.step2-content', {framework:'React'})}
                </Typography>
              </Box>
              <div className="d-lg-none d-sm-block float-start col-sm col-lg-6 col-md-12 image-block align-self-center">
                <div className="pl-20">
                  <img src={iconarrowleft} className="h-[50px]" alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </Box>
      {/* Field 4 end */}




      {/* Field 5 */}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: COLOR_WHITE,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { xs: "20px", md: "190px" },
          paddingBottom: { xs: "40px", md: "250px" }
        }}
      >
        <div className="container">
          <div className="row">
            
            <div className="col-sm col-lg-6 col-md-12  align-self-center content-block-text">
              <Box component="main" sx={{ pt: {xs: "50px", md: "0px"}, pb: 0, pl: {xs: "0px", md: "220px"}}}>
                <Typography align="left" color="text.secondary" component="p" sx={{ fontSize:{ xs: "22px", md: "22px" }}}>
                    {t('pages.how.step3-title', {framework:'React'})}
                  </Typography>
                  <Typography align="left" color="secondary.main" component="p" sx={{ paddingBottom: "20px", }}>
                    {t('pages.how.step3-content', {framework:'React'})}
                  </Typography>
              </Box>
              <div className="d-lg-none d-sm-block float-end col-sm col-lg-6 col-md-12 image-block align-self-center">
                <div className="pl-20">
                  <img src={iconarrowright} className="h-[50px]" alt="" />
                </div>
              </div>
            </div>
            <div className="d-none d-lg-block col-sm col-lg-6 col-md-12 image-block align-self-center">
              <div className="pl-20">
                <img src={iconarrowright} className="h-[90px]" alt="" />
              </div>
            </div>

          </div>
        </div>
      </Box>
      {/* Field 5 end */}

      {/* Field 6 */}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: COLOR_PINK_LIGHT_BG,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { xs: "20px", md: "90px" },
          paddingBottom: { xs: "40px", md: "90px" }
        }}
      >
        <div className="container">
          <div className="row">
            
            <div className="d-none d-lg-block col-sm col-lg-6 col-md-12 image-block align-self-center">
                <div className="float-lg-end float-xl-end">
                  <img src={iconarrowleft} className="h-[60px]" alt="" />
                </div>
            </div>

            <div className="col-sm col-lg-6 col-md-12  align-self-center content-block-text">
              <Box component="main" sx={{  pt: 0, pb: 0, pl: {xs: "0px", md: "0px"}}}>
                <Typography align="left" color="text.secondary" component="p" sx={{fontSize:{ xs: "22px", md: "22px" }, paddingBottom: "20px", }}>
                  {t('pages.how.step4-title', {framework:'React'})}
                </Typography>
                <Typography align="left" color="secondary.main" component="p" sx={{ paddingBottom: "60px", }}>
                  {t('pages.how.step4-content', {framework:'React'})}
                </Typography>
              </Box>
              <div className="d-lg-none d-sm-block float-start col-sm col-lg-6 col-md-12 image-block align-self-center">
                <div className="pl-20">
                  <img src={iconarrowleft} className="h-[20px]" alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </Box>
      {/* Field 6 end */}



      {/* Field 7 */}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: COLOR_WHITE,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { xs: "20px", md: "90px" },
          paddingBottom: { xs: "40px", md: "90px" }
        }}
      >
        <div className="container">
          <div className="row">
            
            <div className="col-sm col-lg-6 col-md-12  align-self-center content-block-text">
              <Box component="main" sx={{ pt: {xs: "50px", md: "0px"}, pb: 0, pl: {xs: "0px", md: "220px"}}}>
                <Typography align="left" color="text.secondary" component="p" sx={{fontSize:{ xs: "22px", md: "22px" }, paddingBottom: "20px", }}>
                    {t('pages.how.step5-title', {framework:'React'})}
                  </Typography>
                  <Typography align="left" color="secondary.main" component="p" sx={{ paddingBottom: "60px", }}>
                    {t('pages.how.step5-content', {framework:'React'})}
                  </Typography>
                  <Typography align="left" color="secondary.main" component="p" sx={{ paddingBottom: "10px", }}>
                  <a href="/pricing"><Button variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                    {t('pages.how.step5-button')}
                  </Button></a>
                </Typography>
              </Box>
              <div className="d-lg-none d-sm-block float-end col-sm col-lg-6 col-md-12 image-block align-self-center">
                <div className="pl-20">
                  <img src={iconarrowright} className="h-[80px]" alt="" />
                </div>
              </div>
            </div>
            <div className="d-none d-lg-block col-sm col-lg-6 col-md-12 image-block align-self-center">
              <div className="pl-20">
                <img src={illustration_relax_home} className="h-[250px]" alt="" />
              </div>
            </div>

          </div>
        </div>
      </Box>
      {/* Field 7 end */}





    </>   
  );
};

export default How;