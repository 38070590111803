import React from 'react'
import {useTranslation} from "react-i18next";
import { LoginPageSwitch } from './LoginPageSwitch'

interface Props {
    resetMetod: string
}

//export const LostPasswordConfirmed = (props: Props): JSX.Element => {
export const LostPasswordConfirmed = (): JSX.Element => {
    const {t, i18n} = useTranslation('common');

    return (
        <div>
            {
                //props.resetMetod === 'SMS'
                false
                ? <div>
                    <h1 className="login-header ch-text-center ch-text-5xl ch-font-bold ch-mb-16">{t('pages.lostpassword.LostPasswordConfirmedHeading')}</h1>
                    <p className="ch-text-center ch-mb-16">{t('pages.lostpassword..LostPasswordConfirmedCheckEmail')}</p>
                </div>
                : <div>
                     <h1 className="login-header ch-text-center ch-text-5xl ch-font-bold ch-mb-16">{t('pages.lostpassword.LostPasswordConfirmedHeading')}</h1>
                    <p className="ch-text-center ch-mb-16">{t('pages.lostpassword.LostPasswordConfirmedCheckEmail')}</p>
                </div>
            }
            <div className="ch-text-center">
                <LoginPageSwitch />
            </div>
        </div>)
}

export default LostPasswordConfirmed;