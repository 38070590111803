import React, { ReactElement, FC } from "react";
import { Box, Typography } from "@mui/material";
import {useTranslation} from "react-i18next";
import { AuthCheckRedirect } from "../services/auth.service";

const paragraphs = [
  {
    title: 'pages.privacy.content.p1.title',
    text: 'pages.privacy.content.p1.text'
  },
  {
    title: 'pages.privacy.content.p2.title',
    text: 'pages.privacy.content.p2.text'
  },
  {
    title: 'pages.privacy.content.p3.title',
    text: 'pages.privacy.content.p3.text'
  },
  {
    title: 'pages.privacy.content.p4.title',
    text: 'pages.privacy.content.p4.text'
  },
  {
    title: 'pages.privacy.content.p5.title',
    text: 'pages.privacy.content.p5.text'
  },
  {
    title: 'pages.privacy.content.p6.title',
    text: 'pages.privacy.content.p6.text'
  },
  {
    title: 'pages.privacy.content.p7.title',
    text: 'pages.privacy.content.p7.text'
  },
  {
    title: 'pages.privacy.content.p8.title',
    text: 'pages.privacy.content.p8.text'
  },
  {
    title: 'pages.privacy.content.p9.title',
    text: 'pages.privacy.content.p9.text'
  },
  {
    title: 'pages.privacy.content.p10.title',
    text: 'pages.privacy.content.p10.text'
  },
  {
    title: 'pages.privacy.content.p11.title',
    text: 'pages.privacy.content.p11.text'
  },
  {
    title: 'pages.privacy.content.p12.title',
    text: 'pages.privacy.content.p12.text'
  },
  {
    title: 'pages.privacy.content.p13.title',
    text: 'pages.privacy.content.p13.text'
  },
  {
    title: 'pages.privacy.content.p14.title',
    text: 'pages.privacy.content.p14.text'
  }
];

const Privacy: FC<any> = (): ReactElement => {
  const {t, i18n} = useTranslation('common');
  
  // Navigate redirect to /my if user is set
  AuthCheckRedirect();
  //

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: { xs: "10px", md: "0px" },
        paddingRight: { xs: "10px", md: "0px" },
      }}
    >
      <Box maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
        </Typography>
        <Typography variant="h4" align="center" color="text.primary" component="p">
          {t('pages.privacy.title')}
        </Typography>

        {paragraphs.map((paragraph) => (
          <Box component="main"  sx={{  paddingTop: "30px" }}>
            <Typography paragraph={true} align="center" color="text.primary" component="p">
              {t(paragraph.title)}
            </Typography>
            <Typography paragraph={true} align="center" color="text.primary" component="p">
              {t(paragraph.text)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Privacy;