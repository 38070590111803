import React, { FC, ReactElement, useState, useEffect, useCallback } from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import {useTranslation} from "react-i18next";
import CoreMonthDatePicker from "../../Elements/Core/CoreMonthDatePicker";
import CoreDateTimePicker from "../../Elements/Core/CoreDateTimePicker";
import moment from "moment";
import CoreBorderBox from "../../Elements/Core/CoreBorderBox";
import CoreBorderBoxInnerWide from "../../Elements/Core/CoreBorderBoxInnerWide";
import UserBudgetTable from "../../Elements/Budget/UserBudgetTable";
import { GetPeriod, SetPeriod } from "../../../services/period.service";

export const BudgetLayout: FC = (): ReactElement => {
  
    const {t, i18n} = useTranslation('common');
    
    const [period, setPeriod] = useState<any>(GetPeriod());
    const periodHandler = (date: any) => {
      SetPeriod(date);
      setPeriod(GetPeriod());
  };

    return (
        <Box>
          <CoreBorderBox >
            <Typography component="p" variant="h5" color="text.secondary">
                {t('pages.my.budget.heading')} 
                <Typography component="span" color="text.secondary" >
                  <span> </span>
              </Typography>
            </Typography>
            <CoreMonthDatePicker 
                text={t('core.date-month-picker-period')}
                date={period}
                onChange={periodHandler}
            />
          </CoreBorderBox>
          <UserBudgetTable period={period} />
        </Box>
    );
  };
  
  export default BudgetLayout;