
import { Box } from "@mui/material";

export const CoreBorderBox = ({ children, ...props }: any) => {

  const customflexDirection = { xs: props.flexDirextionXs??"column", md: props.flexDirextionMd??"row" };

  return (
    <Box
      sx={{
        p: props.padding ?? 1,
        m: 0,
        marginBottom: props.marginBottom ?? 3,
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '0px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '400',
        display: 'flex',
        flexDirection: customflexDirection,
        justifyContent: 'space-between',
      }}
    >
      {children}
    </Box>
  );
};

export default CoreBorderBox;