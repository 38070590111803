import React, { FC, ReactElement, useState, useEffect, useCallback, useRef } from "react";
import { Modal, Button as ReactBootstrapButton } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IUserTotalsAccounts, { IAccount } from "../../../DataModels/Dashboard/UserTotalsAccountsModel";
import IUserTotalsTransactions, { ITransaction } from "../../../DataModels/Dashboard/UserTotalsTransactionsModel";
import Grid from '@mui/material/Grid';
import CoreBorderBox from "../../Core/CoreBorderBox";
import CoreBorderBoxInner from "../../Core/CoreBorderBoxInner";
import DashboardElementUserTotalsTransactions from "../UserTotalsTransactions";
import { getAccountsData, getTransactionsData } from "../../../../services/totals.service";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CoreModalBasic } from "../../Core/CoreModalBasic";
import { CoreModalStatic } from "../../Core/CoreModalStatic";
import { GetPeriod } from "../../../../services/period.service";
import { deleteBankingAccount } from "../../../../services/banking.service";
import { cancelIntegration, CancelIntegrationResponse } from "../../../../services/user.service";
import { CoreAlertDialogConfirm } from "../../Core/CoreAlertDialogConfirm";

export enum DashboardAccountsModalIdEnum {
	EDIT = "edit",
	DELETE = "delete"
}

function DeleteConfirmModal() {
    const { t, i18n } = useTranslation('common');

    return (
        <>
        
        </>
    )
}

export function DashboardAccountsEditModal() {
    const { t, i18n } = useTranslation('common');

    // Input data
    const { id } = useParams();

    // Transactions data
    const [userTotalsTransactions, setUserTotalsTransactions] = useState<IUserTotalsTransactions>();
    const [period, setPeriod] = useState<any>(null);

    const [userTotalsAccounts, setUserTotalsAccounts] = useState<IUserTotalsAccounts>();
    async function fetchAccountsData() {
        const response = await getAccountsData(period);
        const json = await response.data;
        setUserTotalsAccounts(json.data);
    }
    useEffect(() => 
        {
            fetchAccountsData();
            setIsPosted(false);
        },
        []
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [isPosted, setIsPosted] = useState<boolean>(false);

    const handleAccountAcction = (action:any, account:IAccount) => {
        if (action == DashboardAccountsModalIdEnum.DELETE)  {
            
            setLoading(true);
            setIsPosted(true);
            let accountId = account.accountId;
            /////
            deleteBankingAccount(accountId)
            .then(
                (response) => {
                    
                  setLoading(false);
                  setSuccess(response.success);
                },
                (error) => {
                  setLoading(false);
                  setMessage(error.message);
                  setSuccess(false);
                }
              );

            /////
        }
    };

    

    // Delete confirm modal settings
    const [deleteMessage, setDeleteMessage] = useState("");
    const [deleteAccount, setDeleteAccount] = useState();
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

    const showDeleteModal = (account:any) => {

        setDeleteAccount(account);
        setDeleteMessage(t('pages.my.dashboard.usertotals-transactions-account-delete-are-you-sure'));
        setShowDeleteConfirmModal(true);

    };
    const hideDeleteConfirmModal = () => {
        setShowDeleteConfirmModal(false);
    };


    const submitDeleteConfirmModal = (account : IAccount) => {
        handleAccountAcction(DashboardAccountsModalIdEnum.DELETE, account)
        setDeleteMessage(t('pages.my.dashboard.usertotals-transactions-account-delete-success'));
        
        setShowDeleteConfirmModal(false);
      };

    const DeleteConfirmation = ({ showModal, hideModal, confirmDeleteModal, account, message }: any) => {
        return (
            <Modal show={showModal} onHide={hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="alert alert-danger">
                    <p>{message}</p>
                    <span>{account.accountName} ({account.accountNumber})</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
              <ReactBootstrapButton variant="default" onClick={hideModal}>
                Cancel
              </ReactBootstrapButton>
              <ReactBootstrapButton variant="danger" onClick={() => confirmDeleteModal(account) }>
                Delete
              </ReactBootstrapButton>
            </Modal.Footer>
          </Modal>
        )
    }


    // Delete confirm modal settings end


    const returnLoading = () => {
        return(
        <>
        <Grid item textAlign={("left")}>
            <p>{t('pages.my.dashboard.usertotals-transactions-account-delete-loading')}</p>
        </Grid>
        </>
        )
    }
    const returnError = () => {
        return(
        <>
        <Grid item textAlign={("left")}>
            <p>{t('pages.my.dashboard.usertotals-transactions-account-delete-error')}</p>
        </Grid>
        </>
        )
    }
    const returnSuccess = () => {
        return(
        <>
        <p>{t('pages.my.dashboard.usertotals-transactions-account-delete-success')}</p>
        </>
        )
    }

    const returnDefault = () => {
        return(
        <>
        <div className="">
            {userTotalsAccounts?.accounts?.map((account:IAccount, idx:any) => (
            <div className="row" key={idx}>
                <div className="col-md-12 col-sm pb10">
                    <div className="row">
                        <div className="col-md-8 col-sm">
                            <span className="fl font-bold">{t('pages.my.dashboard.usertotals-transactions-account-name')} :</span>
                            <span className="fl">{account.accountName}</span>
                        </div>
                        <div className="col-md-4 col-sm">
                        <span className="fr" color="red"><Button className="ch-bg-pink rounded-md bg-[#20B486]"><DeleteIcon onClick={() => showDeleteModal(account)} fontSize="inherit" /></Button></span>
                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm">
                            <span className="fl font-bold">{t('pages.my.dashboard.usertotals-transactions-account-account')} :</span>
                            <span className="fl">{account.accountNumber}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm">
                            <span className="fl font-bold">{t('pages.my.dashboard.usertotals-transactions-account-balance')} :</span>
                            <span className="fl">{account.balance}</span>
                        </div>
                    </div>
                </div>
            </div>
            ))}
        </div>
        </>
        )
    }

    
    return (
        <>
        {showDeleteConfirmModal && (
            <DeleteConfirmation showModal={showDeleteConfirmModal} hideModal={hideDeleteConfirmModal} confirmDeleteModal={submitDeleteConfirmModal} account={deleteAccount} message={deleteMessage}  />
        )}

        {!showDeleteConfirmModal && (
            <CoreModalStatic closeUrl="/my/dashboard" subheader={t('pages.my.dashboard.usertotals-transactions-account-accounts')}>
                <>
                <Box>
                {loading && isPosted  && (returnLoading()) }
                {!loading && success && isPosted && (returnSuccess()) }
                {!loading && !success && isPosted && (returnError()) }
                {!isPosted && (returnDefault()) }
                </Box>
                </>
            </CoreModalStatic>
        )}
        </>
    );
}