
import { FC, ReactElement, useState, useEffect } from "react";


export const APP_URL = "https://whybie.no";
export const APP_API_URL = "https://api.whybie.no/api";
export const APP_URL_DEV = "https://localhost:3000";
export const APP_API_URL_DEV = "https://localhost:7079/api";

export const COLOR_LOGO = "#f9c901";
export const COLOR_PINK_MAIN_TEXT = "#985b10";
export const COLOR_PURPLE_DARK_BG = "#4a3100";
export const COLOR_PINK_LIGHT_BG = "#fdf8cc";
export const COLOR_WHITE_BACKGROUND = "#ffffff";
export const COLOR_BLACK = "#000000";
export const COLOR_WHITE = "#ffffff";
export const COLOR_FOOTER_HEADING = "#ffffff";
export const COLOR_FOOTER_TEXT = "#ffffff";


var url = window.location.pathname;
var appUrl = "https://whybie.no";
var appApiUrl = "https://api.whybie.no/api";
var appUrlLocal = "https://localhost:3000";
var appApiUrlLocal = "https://localhost:7079/api";

const APP_URL2: FC = (): ReactElement => {
    const [APP_URL, setAppUrl] = useState<any>(appUrl);

    useEffect(() => {
        setAppUrl(url.includes('localhost') ? appUrlLocal : appUrl);
    },);

    return (
        APP_URL
    );
};
