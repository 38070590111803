import React, { ReactElement, FC, useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Link, Outlet } from "react-router-dom";
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import CoreBorderBox from "../../components/Elements/Core/CoreBorderBox";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getCurrentUser, updateWhoAmI } from "../../services/auth.service";
import * as AuthService from "../../services/auth.service";

const logOut = () => {
  AuthService.logout();
};
const authCheck = () => {
  AuthService.logout();
};

const Setting: FC<any> = (): ReactElement => {
  const {t, i18n} = useTranslation('common');
  
  const [currentUser, setCurrentUser] = useState(getCurrentUser());
  const [isUpdated, setIsUpdated] = useState(false);
  let navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    updateWhoAmI();
    setIsUpdated(true);
  }, []);

  useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      setCurrentUser(getCurrentUser());
    }, 3000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);

  /*
  useEffect(() => {
    if(isUpdated) {
      setCurrentUser(getCurrentUser());
      
    }
  }, [isUpdated]);
  */
  if (currentUser == null) {
    // Auth check is done in App
    //navigate("/my/dashboard");
    return (<Box></Box>);
    //window.location.reload();
  }
  return (
    <Box>
      <CoreBorderBox>
        <Typography component="p" variant="h5" color="text.secondary">
          {t('pages.my.setting.heading', {framework:'React'})}
        </Typography>
      </CoreBorderBox>

      <CoreBorderBox>
       
        <div className="container">
          <div className="ml-auto">
            <p>
              <strong>{t('pages.my.setting.username')}:</strong> {currentUser?.username}
            </p>
          </div>
          <div className="ml-auto">
            <p>
              <strong>{t('pages.my.setting.user-profile')}:</strong> {currentUser?.role }
            </p>
          </div>

          

          <div>
            <a href="/my/checkout">
              <Button variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                {t('pages.my.setting.button-checkout')}
              </Button>
            </a>
          </div>
          <div>
            <a href="/login" onClick={logOut}>
              <Button variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                {t('pages.my.setting.logout')}
              </Button>
            </a>
          </div>
          
        </div>
      </CoreBorderBox> 
    </Box>
  );
};

export default Setting;