import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../services/EventBus";
import { getCurrentUser, checkAuth } from "./auth.service";
import { APP_API_URL, APP_API_URL_DEV } from "../Config/Constants";

var apiUrl = window.location.hostname.includes('localhost') ? APP_API_URL_DEV : APP_API_URL;
const API_URL = apiUrl+"/oauth/";
  
export const getOauthInstitutionIdsData = () => {
  checkAuth();
  var response = axios.get(API_URL + "oauthInstitutionIds", { headers: authHeader() });
  return response;
};

export const getOauthConnectData = (institutionId: string) => {
  checkAuth();
  var response = axios.get(API_URL + "oauthConnect?institutionId="+institutionId, { headers: authHeader() });
  return response;
};

