import React, { ReactElement, FC } from "react";
import { useLocation } from 'react-router-dom';
import { routes } from "./routes";
import { BrowserRouter as Router, HashRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginForm from "./components/Elements/Login/LoginForm";
import LostPasswordForm from "./components/Elements/Login/LostPasswordForm";
import LostPasswordConfirmed from "./components/Elements/Login/LostPasswordConfirmed";
import ResetPasswordForm from "./components/Elements/Login/ResetPasswordForm";
import SignupForm from "./components/Elements/Login/SignupForm";
import LayoutLogin from "./components/Layout/LayoutLogin";
import LayoutOauth from "./components/Layout/LayoutOauth";
import { LayoutMy } from "./components/Layout/LayoutMy";
import { LayoutDefault } from "./components/Layout/LayoutDefault";
import { DashboardTransactionsModal } from "./components/Elements/Dashboard/Modal/DashboardTransactionsModal";
import { DashboardAccountsEditModal } from "./components/Elements/Dashboard/Modal/DashboardAccountsEditModal";
import { DashboardAccountsSettingsModal } from "./components/Elements/Dashboard/Modal/DashboardAccountsSettingsModal";
import { AuthCheck } from "./services/auth.service";


export const MyRouter: FC<any> = (): ReactElement => {
    return (
      <Router>
        <MyRouterRoutes />
      </Router>
    );
};

function MyRouterRoutes() {

  let path = window.location.href;
  let isLogin = window.location.pathname.includes("/login")
  let isOauth = window.location.pathname.includes("/oauth")
  let isMy = window.location.pathname.includes("/my")
  const LayoutComponent = 
    isLogin 
    ? LayoutLogin
    : ( isOauth
        ? LayoutOauth
        : ( isMy
            ? LayoutMy
            : LayoutDefault
          )
      )
  ;

  const location = useLocation();
  const previousLocation = location?.state?.previousLocation;

  return (
      <LayoutComponent>
        <Routes location={previousLocation || location}>
          {routes.map((route) => (
            (route.authenticate && !AuthCheck(true) ) // !route.isAuthenticated )  // solve double login error
            ?
            <Route
            key={"redirect"}
            path={route.path}
            element={ <Navigate to="/login" /> }
            />
            :
            <Route
            key={route.key}
            path={route.path}
            element={<route.component />}
          />
          ))}
          <Route
            path="/login/lostPassword"
            element={<LostPasswordForm />}
          >
          </Route>
          <Route
            path="/login/lostPasswordConfirmed"
            element={<LostPasswordConfirmed />}
          >
          </Route>
          <Route
            path="/login/resetPassword"
            element={<ResetPasswordForm />}
          >
          </Route>
          <Route 
            path="/login/signup"
            element={<SignupForm />}
          >
          </Route>
          <Route
            path="/login"
            element={<LoginForm />}
          >
          </Route>
        </Routes>
        {previousLocation && (
          <Routes>
            <Route path="/my/dashboard/transactions/:id" element={<DashboardTransactionsModal />} />
            <Route path="/my/dashboard/accounts/:id" element={<DashboardAccountsEditModal />} />
            <Route path="/my/dashboard/accounts/settings" element={<DashboardAccountsSettingsModal />} />
          </Routes>
        )}
      </LayoutComponent>
  );
}

export default MyRouter;