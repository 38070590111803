import React, { FC, ReactElement } from "react";
import {
  Box,
  Link,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { routes } from "../../routes";
import { NavLink } from "react-router-dom";
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import {useTranslation} from "react-i18next";
import { getCurrentUser } from "../../services/auth.service";
import * as Icon from 'react-bootstrap-icons';
import {logo,lock, hamburgerMenu, close} from '../../assets'
import { COLOR_LOGO } from "../../Config/Constants";


const Nav1 = () => {
  
  const [toggle,setToggle] = React.useState(false);
  const handleClick = ()=> setToggle(!toggle);

  return (
    <div className="w-full h-[80px] border-b ">
        <div className='md:max-w-[1480px] max-w-[600px] m-auto w-full h-full flex justify-between items-center md:px-0 px-4'>
            
            <img src={logo} className="h-[25px]" alt="" />
            <div className='hidden md:flex items-center '>
                <ul className='flex gap-4'>
                    <li>Home</li>
                    <li>About</li>
                    <li>Support</li>
                    <li>Platform</li>
                    <li>Pricing</li>
                </ul>
            </div>


            <div className='hidden md:flex'>
                <button className='flex justify-between items-center  bg-transparent  px-6 gap-2'>
                    <img src={lock} alt="" />
                    Login
                </button>
                <button className='px-8 py-3 rounded-md bg-[#20B486] text-white font-bold'>Sign Up For Free</button>
            </div>

            <div className='md:hidden'  onClick={handleClick}>
                    <img src={toggle?close:hamburgerMenu} alt="" />
            </div>
        </div>

        <div className={toggle?'absolute z-10 p-4  bg-white w-full px-8 md:hidden border-b':'hidden'}>
            <ul>
                    <li className='p-4 hover:bg-gray-100'>Home</li>
                    <li className='p-4 hover:bg-gray-100'>About</li>
                    <li className='p-4 hover:bg-gray-100'>Support</li>
                    <li className='p-4 hover:bg-gray-100'>Platform</li>
                    <li className='p-4 hover:bg-gray-100'>Pricing</li>
                    <div className='flex flex-col my-4 gap-4'>
                        <button className='border border-[20B486] flex justify-center items-center  bg-transparent  px-6 gap-2 py-4'>
                         <img src={lock} alt="" />
                         Login
                        </button>
                        <button className='px-8 py-5 rounded-md bg-[#20B486] text-white font-bold'>Sign Up For Free</button>
                    </div>
            </ul>
        </div>
    </div>
  )
};



const Navbar: FC = (): ReactElement => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const currentUser = getCurrentUser();
  let isAuthenticated = currentUser != null;
 
  let logoUrl = window.location.pathname.includes("/my")
    ? "/my/dashboard"
    : "/"
    ;
  
  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const {t, i18n} = useTranslation('common');

  const [scroll, setScroll] = React.useState(0);

  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const className = scroll > 50 ? "b-nav-t b-nav-s" : "b-nav-t";

  return (
    <div className={className} >
    <Box
      sx={{
        width: "100%",
        height: {xs: "70px", md: "90px"},
      }}
    >
      <Box sx={{ textAlign: "center" }} >
        <Box sx={{ margin: "0 auto", maxWidth: "1200px",  padding: "0 10px" }}  //maxWidth: "1000px"
        >
          <AppBar 
            position="static"
            color="transparent"
            elevation={0}
            sx={{ borderBottom: (theme) => `0px solid ${theme.palette.divider}` }}
          >
            <Toolbar  sx={{ flexWrap: 'wrap', marginTop: { xs: "0rem", md: "1rem" } }} disableGutters>

              {/* Logo mobile and desktop */}
              <Box
                sx={{
                  flexGrow: { xs: 0.1, md: 1 },
                  display: { xs: "flex", md: "flex" },
                }}
              >
                <Box sx={{marginLeft: { xs: "0rem", md: "0rem" },}}>
                  <a href={logoUrl}><img src={logo} className="h-[40px]" alt="whybie logo - why budget is easy" /></a>
                  <div  style={{ color: COLOR_LOGO, fontSize:"10px", textAlign:"right", paddingRight:"5px" }}>Why Budget Is Easy</div>
                </Box>
              </Box>
              {/* Logo mobile and desktop end */}
              
              {/* Navigation mobile */}
              <Box sx={{ flexGrow: 1, flexDirection: "row-reverse", display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {routes.map((page) => (
                    <div key={page.key} className="">
                    {page.enabled && isAuthenticated == page.authenticate &&
                    (
                      <Link
                        key={page.key}
                        component={NavLink}
                        to={page.path}
                        color="black"
                        underline="none"
                        variant="button"
                      >
                        <MenuItem onClick={handleCloseNavMenu}>
                          <Typography textAlign="center">{t(page.title_key)}</Typography>
                        </MenuItem>
                      </Link>
                    )}
                    </div>
                  ))}

                  {!isAuthenticated && 
                  (
                  <div key="login" className="">
                    <a href="/login">
                    <Button variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                          {t('header.login')}
                        </Button>
                    </a>
                  </div>
                  )}
                  {isAuthenticated && 
                  (
                    <div key="settings" className="">
                    <Box sx={{ display: { xs: "flex", md: "flex" }, padding: "0px 0 0 15px" }}>
                    <Link
                      key={'profile-route'}
                      component={NavLink}
                      to={'/my/setting'}
                      color="black"
                      underline="none"
                      variant="button"
                    >
                      <Icon.Gear
                        color="purple"
                        size={18}
                        className="align-top"
                      />
                    </Link>
                  </Box>
                  </div>
                  )}
                </Menu>
              </Box>
              {/* Navigation mobile end */}

              {/* Navigation desktop */}
              <nav>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginLeft: "7rem",
                          }}
                        >
                          {routes.map((page) => (
                            <div key={page.key} className="navbar-nav mr-auto header-nav-item">
                              {page.enabled && isAuthenticated === page.authenticate &&
                              (
                                <Link
                                  key={page.key}
                                  component={NavLink}
                                  to={page.path}
                                  color="black"
                                  underline="none"
                                  variant="button"
                                  sx={{ my: 1, mx: 1.5, letterSpacing: "0.2rem", fontSize: "0.8rem", marginLeft: "2rem" }}
                                >
                                  {t(page.title_key)}
                                </Link>
                              )} 
                            </div>
                          ))}
                        </Box>
                        {isAuthenticated && false && 
                        ( 
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginLeft: "auto",
                            marginRight: "1rem"
                          }}
                        >
                          <div key="login" className="header-nav-item">
                          <a href="/login">
                            <Button variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                                  {t('header.login')}
                                </Button>
                            </a>
                          </div>
                        </Box>
                        )}
              </Box>
              </nav>
              {/* Navigation desktop end */}

              {/* Login desktop */}
              {!isAuthenticated && (
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <a href="/login" >
                    <Button variant="outlined" sx={{ my: 1, mx: 1.5, textDecoration: "none" }}>
                        {t('header.login')}
                      </Button>
                  </a>
              </Box>
              )}
              {isAuthenticated && (
                <Box sx={{ display: { xs: "none", md: "flex" }, padding: "6px 0 0 30px" }}>
                  <Link
                    key={'profile-route'}
                    component={NavLink}
                    to={'/my/setting'}
                    color="black"
                    underline="none"
                    variant="button"
                  >
                    <Icon.Gear
                      color="purple"
                      size={18}
                      className="align-top"
                    />
                  </Link>
                </Box>
              )}
              {/* Login desktop end */}

            </Toolbar>
          </AppBar>
        </Box>
      </Box>
    </Box>
    </div>
  );
  

};

export default Navbar;
