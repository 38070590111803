import { ReactElement, FC, useState, useEffect, useCallback } from "react";
import {useTranslation} from "react-i18next";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getCurrentUser } from "../../services/auth.service";
import BudgetLayout from "../../components/Layout/Budget/BudgetLayout";

const Budget: FC<any> = (): ReactElement => {
  const {t, i18n} = useTranslation('common');
  const currentUser = getCurrentUser();
  let navigate: NavigateFunction = useNavigate();
  if (currentUser == null) {
    navigate("/login");
  }
  
  return (
    <BudgetLayout />
  );
};

export default Budget;