import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export function CoreCircularWithValueLabel({ ...props }: any) {
  const [progress, setProgress] = React.useState(10);
  const [done, setDone] = React.useState(false);

  let timeLengthMilliSeconds = props?.timeLengthMilliSeconds ?? 800;
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress > 100 ? 0 : prevProgress + 10));
    }, timeLengthMilliSeconds);
    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    if(progress >= 100) {
        setDone(true);
    }
  }, [progress]);

  if(done) {
    return <CheckCircleIcon color="primary" />;
  }
  return <CircularProgressWithLabel value={progress} />;
}