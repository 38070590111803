import { Validator } from '../Utils/Validator';
import * as Yup from 'yup';


export const getInitialValues = () => ({
    email: '',
    password: ''
})

export const validationSchema = Validator.object().shape({
    email: Validator
        .string()
        .required()
        .email('core.FieldIsValidEmail')
        .test('email', 'core.FieldIsValidEmail', function (email) {
            if (!email) {
                return true;
            }
            const { path, createError } = this;
            const domainName = email.split('.').pop() ?? "";
            if (domainName.toLowerCase() === 'con') {
                return createError({
                    path,
                    message: "wp.EmailTopDomainValidate"
                });
            } else {
                return true;
            }
        }),
    username: Yup
        .string()
        .required(),
    password: Yup
        .string()
        .required()
})