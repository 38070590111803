import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { IconButton } from "@mui/material";
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: "80%",
    bgcolor: 'background.paper',
    border: '1px solid #eee',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: "scroll"
  };
  const styles = (theme:any) => ({
    modal:{
      position:'absolute',
      top:'10%',
      left:'10%',
      overflow:'hidden',
      height:'100%',
      maxHeight: 500,
      display:'block'
    },
    header: {
      padding: '12px 0',
      borderBottom: '1px solid darkgrey'
    },
    content: {
      padding: 12,
      overflow: 'scroll'
    }
  });
  const innerStyle = {
    overflow: "scroll"
  };


export const CoreModalStatic = ({ children, ...props }: any) => {

  const navigate = useNavigate();
  const handleClose = () => {
    navigate(props.closeUrl);
  };

  return (
    <React.Fragment>
      <Modal
        hideBackdrop
        open={true}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
            sx={{
                ...style, 
                height:'100%',
                display: "grid",
                gridTemplateColumns: 'auto',
                gridTemplateRows: 'auto 1fr auto',
                p: { xs: 1, md: 2},
                width: { xs: "100%", md: "60%" },
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    padding: '2px 0',
                    borderBottom: '1px solid darkgrey'
                }}
            >
                <h4>{props.header}</h4>
                {props.subheader && (<p>{props.subheader}</p>)}
            </Box>
            <Box
                sx={{
                    padding: 0.5,
                    overflowY: 'scroll',
                    overflowX: 'hidden'
                }}
            >
                <Box>
                    {React.cloneElement(children, true)}
                </Box>
            </Box>
            <Box
                sx={{
                    
                }}
            >
                <Button onClick={handleClose}>Close</Button>
            </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}