import { FC, ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import {useTranslation} from "react-i18next";
import { getCurrentUser } from "../../services/auth.service";
import { COLOR_PURPLE_DARK_BG } from "../../Config/Constants";
import { COLOR_BLACK, COLOR_FOOTER_HEADING,COLOR_FOOTER_TEXT } from "../../Config/Constants";

import { logo } from '../../assets';
import {FaFacebookF,FaDribbble,FaLinkedinIn,FaInstagram,FaBehance} from 'react-icons/fa'


function Copyright(props: any) {
  return (
    <Typography variant="body2" style={{color:COLOR_FOOTER_TEXT}} align="left" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Whybie 
      </Link>{' '}
      {`${new Date().getFullYear()} `}
    </Typography>
  );
}
function Disclaimer(props: any) {
  return (
    <Typography variant="body2" color="#6D737A" align="left" {...props}>
      {'Icons by '}
      <Link color="inherit" href="https://icons8.com/">
        Icons8 
      </Link>
    </Typography>
  );
}

const footers = [
  {
    title: 'Whybie',
    title_key: 'footer.whybie.title',
    description: [
      {
        text: 'About',
        text_key: 'footer.whybie.about',
        url: '/about'
      },
      {
        text: 'Pricing',
        text_key: 'footer.whybie.pricing',
        url: '/pricing'
      },
      {
        text: 'Contact',
        text_key: 'footer.whybie.contact',
        url: '/contact'
      },
      {
        text: 'Privacy policy',
        text_key: 'footer.legal.privacy',
        url: '/privacy'
      },
      {
        text: 'Terms of use',
        text_key: 'footer.legal.terms',
        url: '/terms'
      }
    ],
  },
  /*
  {
    title: 'Legal',
    title_key: 'footer.legal.title',
    description: [
      {
        text: 'Privacy policy',
        text_key: 'footer.legal.privacy',
        url: '/privacy'
      },
      {
        text: 'Terms of use',
        text_key: 'footer.legal.terms',
        url: '/terms'
      }
    ],
  },*/
];
const Footer_ = () => {
  
  const {t, i18n} = useTranslation('common');
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: COLOR_PURPLE_DARK_BG,
      }}
    >
      <div className='w-full pt-4'>
        <Box sx={{marginLeft: { xs: "1rem", md: "8rem" },}}>
          <a href="/"><img src={logo} className="h-[25px]" alt="" /></a>
        </Box>
      </div>
      <div className='w-full pt-12'>
          
          <div className='md:max-w-[1480px] m-auto grid md:grid-cols-5 max-[780px]:grid-cols-2  gap-8 max-w-[600px]  px-4 md:px-0'>
              
              
              <div className='col-span-2'>
                  <h5 className='font-bold' style={{color:COLOR_FOOTER_HEADING}}>{t('footer.contact.title')}</h5>
                  <p className='py-2' style={{color:COLOR_FOOTER_TEXT}}>{t('footer.contact.text')}</p>
                  <p className='py-2' style={{color:COLOR_FOOTER_TEXT}}>{t('footer.contact.email')}</p>
                  <div className='flex gap-4 py-4'>
                          <div className='p-4 bg-[#E9F8F3] rounded-xl'><FaFacebookF size={10} style={{color:'#6D737A'}} /></div>
                          <div className='p-4 bg-[#E9F8F3] rounded-xl'><FaLinkedinIn size={10} style={{color:'#6D737A'}} /></div>
                          <div className='p-4 bg-[#E9F8F3] rounded-xl'><FaInstagram size={10} style={{color:'#6D737A'}} /></div>

                  </div>
              </div>

              <div>
                  <h5 className='font-bold' style={{color:COLOR_FOOTER_HEADING}}>{t('footer.explore.title')}</h5>
                  {footers.map((footer) => (
                    <div key={footer.title}>
                      <ul className='py-2 pl-2 text-[#6D737A]'>
                        {footer.description.map((item) => (
                          <li className='py-2' key={item.text}>
                            <Link href={item.url} variant="subtitle1" style={{color:COLOR_FOOTER_TEXT}}>
                              {t(item.text_key)}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
              </div>

              <div>
              <h5 className='font-bold' style={{color:COLOR_FOOTER_HEADING}}>{t('footer.solutions.title')}</h5>
                  <ul className='py-2 pl-2 text-[#6D737A]'>
                      <li className='py-2' key='personal-finance'>
                        <Link href='/how#personal-finance' variant="subtitle1" style={{color:COLOR_FOOTER_TEXT}}>
                          {t('footer.solutions.link-personal-finance')}
                        </Link>
                      </li>
                      <li className='py-2' key='budget'>
                        <Link href='/how#budget' variant="subtitle1" style={{color:COLOR_FOOTER_TEXT}}>
                          {t('footer.solutions.link-budget')}
                        </Link>
                      </li>
                      <li className='py-2' key='categorized'>
                        <Link href='/how#categorized' variant="subtitle1" style={{color:COLOR_FOOTER_TEXT}}>
                          {t('footer.solutions.link-categorized')}
                        </Link>
                      </li>
                      <li className='py-2' key='integration'>
                        <Link href='/how#integration' variant="subtitle1" style={{color:COLOR_FOOTER_TEXT}}>
                          {t('footer.solutions.link-integration')}
                        </Link>
                      </li>
                      <li className='py-2' key='automatic'>
                        <Link href='/how#automatic' variant="subtitle1" style={{color:COLOR_FOOTER_TEXT}}>
                          {t('footer.solutions.link-automatic')}
                        </Link>
                      </li>

                  </ul>
              </div>

              <div className='max-[780px]:col-span-2'>
                  <h5 className='font-bold' style={{color:COLOR_FOOTER_HEADING}}>{t('footer.subscribe.title')}</h5>
                  <p className='py-2' style={{color:COLOR_FOOTER_TEXT}}>{t('footer.subscribe.text')}</p>
                  <form className='py-4'>
                      <input 
                          className='bg-[#F2F3F4] p-4 w-full rounded' 
                          placeholder={t('footer.subscribe.email')} 
                      />
                      <button className='max-[780px]:w-full my-4 px-5 py-3 rounded-md bg-[#20B486] text-white font-medium'>{t('footer.subscribe.submit')}</button>

                  </form>
              </div>
          
          </div>
      </div>
      <div className='w-full pb-4'>
        <Box sx={{marginLeft: { xs: "1rem", md: "8rem" },}}>
          <Copyright sx={{ mt: 5 }} />
        </Box>
      </div>
    </Box>
  )
}

export const Footer: FC = (): ReactElement => {
  const {t, i18n} = useTranslation('common');
  const currentUser = getCurrentUser();
  let isAuthenticated = currentUser != null;
  if(isAuthenticated) {
    return (
      <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: COLOR_PURPLE_DARK_BG, // "#b0447e",
        paddingTop: "2rem",
        paddingBottom: "0rem",
      }}
    ></Box>
    );
  }

  return Footer_();
/*
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: COLOR_PURPLE_DARK_BG, // "#b0447e", //rgb(246, 82, 117)", //"#23282d",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      {/* Footer *//*}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Grid sx={{ flexWrap: "nowrap", width: "auto", textAlign:"center" }} container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={0} sm={3} key={footer.title}>
              <Typography variant="h6" color="#ffffff" gutterBottom>
              {t(footer.title_key)}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item.text}>
                    <Link href={item.url} variant="subtitle1" color="#ffffff">
                      {t(item.text_key)}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Copyright sx={{ mt: 5 }} />

        <Disclaimer sx={{ mt: 5 }} />
      </Container>
      {/* End footer *//*}
    </Box>
  );
  */


};

export default Footer;
