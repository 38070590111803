import React, { FC, ReactElement, useState, useEffect, useCallback } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import {useTranslation} from "react-i18next";
import EventBus from "../../../services/EventBus";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getCurrentUser } from "../../../services/auth.service";
import IUserTotals from "../../DataModels/Dashboard/UserTotalsModel";
import IUserTotalsTransactions from "../../DataModels/Dashboard/UserTotalsTransactionsModel";
import { IAccount } from "../../DataModels/Dashboard/UserTotalsAccountsModel";

export const DashboardElementUserTotalsTransactionsAccounts = (props: any) => {
    const {t, i18n} = useTranslation('common');
    const [accounts, setAccounts] = useState<[IAccount]>();
    const [isLoading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        setAccounts(props.accounts);
        setLoading(props.accounts == null);
    }, [props]);
    if (isLoading) {
        return (
            <div>{t('pages.my.dashboard.usertotals-loading')}</div>
        );
    }
    return (
        <div className="">
            {accounts?.map((account, idx) => (
                <div className="row" key={idx}>
                    <div className="col-md-12 col-sm pb10">
                        <div className="row">
                            <div className="col-md-12 col-sm">
                                <span className="fl">{t('pages.my.dashboard.usertotals-transactions-account-account')} :</span>
                                <span className="fl font-bold">{account.accountNumber}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm">
                                <span className="fl">{t('pages.my.dashboard.usertotals-transactions-account-balance')} :</span>
                                <span className="fl font-bold">{account.balance}</span>
                            </div>
                        </div>
                    </div>
                </div>
                
            ))}


            
        </div>
    );
}

export default DashboardElementUserTotalsTransactionsAccounts;
