import React, {  ReactElement, FC, useCallback, useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { Box, Typography } from "@mui/material";
import {useTranslation} from "react-i18next";
import CoreBorderBox from "../../components/Elements/Core/CoreBorderBox";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getCurrentUser } from "../../services/auth.service";
import * as AuthService from "../../services/auth.service";

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.

          If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    )
  }

  return null;
}


  // old 
const logOut = () => {
  AuthService.logout();
};
const authCheck = () => {
  AuthService.logout();
};

export const CheckoutReturn: FC<any> = (): ReactElement => {
  const {t, i18n} = useTranslation('common');
  const currentUser = getCurrentUser();
  let navigate: NavigateFunction = useNavigate();

  if (currentUser == null) {
    return (<Box></Box>);
  }
  
  return (
    <Box>
      <CoreBorderBox>
        <Typography component="p" variant="h5" color="text.secondary">
          {t('pages.my.checkout.heading', {framework:'React'})}
        </Typography>
      </CoreBorderBox>

      <CoreBorderBox>
       
        <div className="container">
          
          <div className="ml-auto">
            <p>
            <strong>{t('pages.my.checkout.description')}</strong>
            </p>
            <Return />
          </div>
          
          
        </div>
      </CoreBorderBox> 
    </Box>
  );
};
