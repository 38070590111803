import {useTranslation} from "react-i18next";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
interface Props {
    childeren?: JSX.Element
}
/*
interface Props {
	pageState: string,
    setPageState: React.Dispatch<React.SetStateAction<string>>
}
*/
export const LoginPageHeader = (props: Props): JSX.Element => {
    const {t, i18n} = useTranslation('common');

    return (
        <Box sx={{paddingLeft: "1rem", marginTop: "1rem",}}>
            <a href="/"><img src="/img/logo.png" height="50px" title="bitsker" alt="Bitsker logo" /></a>
        </Box>
    );
}

export const LoginPageSwitch = (props: Props): JSX.Element => {
    const {t, i18n} = useTranslation('common');
    //const location = useLocation();

    var pathName = window.location.pathname;
	return (
        <span className="ch-text-center">
            {
                ['/lostPassword', '/signup'].includes(window.location.pathname)
                ? <span>
                    <button className="ch-underline" onClick={() => {
                        // props.setPageState('login')
                        window.location.hash = ''
                    }}>{t('pages.lostpassword.LostPasswordBack')}</button>
                </span>
                : pathName.includes('/lostPassword') || pathName.includes('/signup')
                ? <span>
                    <Button className="ch-bg-pink" onClick={() => {
                        // props.setPageState('login')
                        window.location.href = '/login'
                    }}>
                        {t('pages.lostpassword.LostPasswordConfirmedBack')}
                    </Button>
                </span>
                : pathName.includes('/lostPasswordConfirmed')
                ? <span>
                    <Button className="ch-bg-pink" onClick={() => {
                        // props.setPageState('login')
                        window.location.href = '/login'
                    }}>
                        {t('pages.lostpassword.LostPasswordConfirmedBack')}
                    </Button>
                </span>
                : <span>
                    <p className="ch-mb-4">{t('pages.login.NewCustomer')}?</p>
                    <button className="ch-underline" onClick={() => {
                        // props.setPageState('signupPage')
                        window.location.href = "/login/signup"
                    }}>
                        {t('pages.login.CreateAccount')}
                    </button>
                </span>
            }
        </span>
	)
}