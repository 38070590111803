import React, { ReactElement, FC, useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import EventBus from "../../services/EventBus";
import {useTranslation} from "react-i18next";
import { NavigateFunction, useNavigate, useLocation } from 'react-router-dom';
import { getCurrentUser } from "../../services/auth.service";
import OauthConnectModal from "../../components/Elements/Oauth/OauthConnectModal";

const Oauth: FC<any> = (): ReactElement => {
    const {t, i18n} = useTranslation('common');
    const currentUser = getCurrentUser();
    let navigate: NavigateFunction = useNavigate();
    if (currentUser == null) {
      navigate("/login");
    }

    return (
      <OauthConnectModal />
    );
};

export default Oauth;