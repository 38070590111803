import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../services/EventBus";
import { getCurrentUser, checkAuth } from "./auth.service";
import { APP_API_URL, APP_API_URL_DEV } from "../Config/Constants";

var apiUrl = window.location.hostname.includes('localhost') ? APP_API_URL_DEV : APP_API_URL;
const API_URL = apiUrl+"/bankingAccount/";


export const deleteBankingAccount = (accountId:string) => {
  return axios
    .post(API_URL + "deleteAccount", {
      accountId,
    }, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      
      return response.data;
    });
};
