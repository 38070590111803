import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import {useTranslation} from "react-i18next";
import { AuthCheckRedirect } from "../services/auth.service";


const tiers = [
  {
    title: 'pages.pricing.plans.basic.title',
    subheader: 'pages.pricing.plans.basic.subheader',
    currency: 'pages.pricing.plans.basic.currency',
    price: '0',
    period: 'pages.pricing.plans.basic.period',
    description: [
      'pages.pricing.plans.basic.description.1',
      'pages.pricing.plans.basic.description.2',
      'pages.pricing.plans.basic.description.3',
      'pages.pricing.plans.basic.description.4',
      'pages.pricing.plans.basic.description.5'
    ],
    buttonText: 'pages.pricing.plans.basic.button-text',
    buttonLink: '/login/signup?membership=basic',
    buttonVariant: 'outlined',
  },
  {
    title: 'pages.pricing.plans.premium.title',
    subheader: 'pages.pricing.plans.premium.subheader', // 'In development, Most popular, beta'
    currency: 'pages.pricing.plans.premium.currency',
    price: 'pages.pricing.plans.premium.price',
    period: 'pages.pricing.plans.premium.period',
    description: [
      'pages.pricing.plans.premium.description.1',
      'pages.pricing.plans.premium.description.2',
      'pages.pricing.plans.premium.description.3',
      'pages.pricing.plans.premium.description.4',
      'pages.pricing.plans.premium.description.5'
    ],
    buttonText: 'pages.pricing.plans.premium.button-text',
    buttonLink: '/login/signup?membership=premium',
    buttonVariant: 'outlined',
    //buttonLink: '',
    //buttonVariant: 'contained',
  }, 
  /*
  {
    title: 'Enterprise',
    price: '10',
    description: [
      '50 users included',
      '30 GB of storage',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },*/
];


function PricingContent() {
  const {t, i18n} = useTranslation('common');
  
  // Navigate redirect to /my if user is set
  AuthCheckRedirect();
  //
  
  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6, 
        paddingTop: "85px", }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
        </Typography>
        <Typography variant="h4" align="center" color="text.secondary" component="p">
          {t('pages.pricing.plans-title')}
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main" sx={{
        paddingTop: "20px",
        paddingBottom: { xs: "90px", md: "200px" }
      }}>
        <Grid container spacing={5} alignItems="flex-end" 
                   sx={{ display: "flex", flexDirection: "row" }}>
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.subheader === 'Gold' ? 12 : 6}
              md={4}
              sx={{ margin: "0 auto" }}
            >
              <Card
                sx={{
                  minHeight: '400px'
                }}
              >
                <CardHeader
                  title={t(tier.title)}
                  subheader={t(tier.subheader)}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],margin:'0 auto'
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 6
                    }}
                  >
                    <Typography variant="h6" color="text.secondary">
                      {t(tier.currency)}
                    </Typography>
                    <Typography component="h2" variant="h3" color="text.primary" sx={{
                      paddingLeft: '10px'
                    }}>
                      {t(tier.price)}
                    </Typography> 
                    <Typography variant="h6" color="text.secondary">
                    /{t(tier.period)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      minHeight: '150px'
                    }}
                  >
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {t(line)}
                      </Typography>
                    ))}
                  </ul>
                  </Box>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant as 'outlined' | 'contained'}
                    href={tier.buttonLink}
                  >
                    {t(tier.buttonText)}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}