
import { Box } from "@mui/material";

export const CoreBorderBoxInner = ({ children, ...props }: any) => {
  
  const width = props.width ?? "45%";

  return (
    <Box sx={{
      p: 0,
      m: 0,
      width: { xs: "sm", md: width },
      
      fontSize: '0.875rem',
      fontWeight: '800',
    }}
    >
    <Box>{props.heading}</Box>
    <Box
      sx={{
        p: 0.8,
        marginBottom: 3,
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '400',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </Box>
    </Box>
  );
};

export default CoreBorderBoxInner;