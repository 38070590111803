import React, { FC, ReactElement, useState, useEffect, useCallback } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import {useTranslation} from "react-i18next";
import EventBus from "../../../services/EventBus";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getCurrentUser } from "../../../services/auth.service";
import IUserTotals from "../../DataModels/Dashboard/UserTotalsModel";
import IUserTotalsTransactions from "../../DataModels/Dashboard/UserTotalsTransactionsModel";
import { ITransaction } from "../../DataModels/Dashboard/UserTotalsTransactionsModel";

export const DashboardElementUserTotalsTransactions = (props: any) => {
    const {t, i18n} = useTranslation('common');
    const [transactions, setTransactions] = useState<[ITransaction]>();
    const [isLoading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        setTransactions(props.transactions);
        
    setLoading(props.transactions == null);
    }, [props]);

    if (isLoading) {
        return (
            <div>{t('pages.my.dashboard.usertotals-loading')}</div>
        );
    }
    return (
        <div className="">
            {transactions?.map((transaction, idx) => (
                <div className="row" key={idx}>
                    <div className="col-md-12 col-sm pb10">
                        <div className="row">
                            <div className="col-md-12 col-sm">
                                <div className="row">
                                    <span className="fl font-bold">{t('pages.my.dashboard.usertotals-transactions-transaction-amount')} : {transaction.amount}</span>
                                
                                    <span className="fl">{transaction.originalDescription}</span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            ))}


            
        </div>
    );
}

export default DashboardElementUserTotalsTransactions;
