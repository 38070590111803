import { ReactElement, FC } from "react";
import { Box, Typography } from "@mui/material";
import {useTranslation} from "react-i18next";
import { AuthCheckRedirect } from "../services/auth.service";

const About: FC<any> = (): ReactElement => {
  const {t, i18n} = useTranslation('common');
  
  // Navigate redirect to /my if user is set
  AuthCheckRedirect();
  //

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "20px",
        paddingLeft: { xs: "10px", md: "0px" },
        paddingRight: { xs: "10px", md: "0px" },
      }}
    >
      <Box maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
        </Typography>
        <Typography variant="h4" align="center" color="text.secondary" component="p" sx={{ paddingBottom: "40px", }}>
          {t('pages.about.how-about-a')}
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{ paddingBottom: "40px", }}>
          {t('pages.about.how-about-having')}
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" component="p" sx={{ paddingBottom: "100px", fontWeight: "600" }}>
          {t('pages.about.how-about-less')}
        </Typography>
        <Typography variant="h6" align="center" color="black" component="p" sx={{ paddingBottom: "40px", }}>
          {t('pages.about.by-integrating')}
        </Typography>
        <Typography variant="h6" align="center" color="black" component="p" sx={{ paddingBottom: "40px", }}>
          {t('pages.about.we-want-to')}
        </Typography>
        <Typography variant="h6" align="center" color="black" component="p" sx={{ paddingBottom: "40px", }}>
          {t('pages.about.that-is-amazing')}
        </Typography>
        <Typography variant="h4" align="center" color="black" component="p" sx={{ paddingBottom: "40px", }}>
          {t('pages.about.history')}
        </Typography>
        <Typography paragraph={true} align="center" color="black" component="p">
          {t('pages.about.the-lack-of-overview')}
        </Typography>
      </Box>
    </Box>
  );
};

export default About;