import React, { FC, ReactNode } from "react";
import { Box, CssBaseline } from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";

interface LayoutProps {
  children: ReactNode;
}

export const LayoutMy: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          minHeight: "100vh",
          maxWidth: "100vw",
          flexGrow: 1,
        }}
      >
        <Navbar />
        <Box
          sx={{
            width: "100%",
            height: "auto",
            backgroundColor: "#ffffff", // "#22262b",//"secondary.main",
            minHeight: { xs: "95vh", md: "95vh" },
            maxWidth: "100vw",
            paddingLeft: { xs: "10px", md: "0px" },
            paddingTop: { xs: "80px", md: "90px" },
          }}
        >
          <Box sx={{ textAlign: "left", backgroundColor: "#ffffff",  display: " block !important", minHeight: "100%" }} >
            <Box sx={{ margin: "0 auto", maxWidth: "1000px",  padding: "0 0" }}>
              {children}
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default LayoutMy;