import React, { ReactElement, FC, useCallback, useState } from "react";
import { Box, Typography, TextField, Paper, AppBar } from "@mui/material";
import { Formik, Field, Form, ErrorMessage, FormikHelpers, useFormik} from "formik";
import { useField } from 'formik';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import { LoginPageSwitch } from './LoginPageSwitch'
import { resetPassword } from "../../../services/auth.service";
import { getInitialValues, validationSchema } from './LostPasswordFormUtils'
import * as Yup from "yup";
import CoreTextInput from "../Core/CoreTextInput";
import CoreCheckbox from "../Core/CoreCheckbox";
import { CoreMuiTextField } from "../Core/CoreMuiTextField";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
    LinearProgress,
    MenuItem,
    FormControl,
    FormControlLabel,
    AutocompleteRenderInputParams,
    ToggleButton,
  } from '@mui/material';
  import {LocalizationProvider} from '@mui/lab';
  import AdapterDateFns from '@mui/lab/AdapterDateFns';
  import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
  import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
  import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
  import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
  import MuiTextField from '@mui/material/TextField';
  import {
    Autocomplete,
    Select,
    Switch,
    ToggleButtonGroup,
  } from 'formik-mui';
  import { lostPasswordOptionData } from "../Core/CoreData";

interface Props {
    childeren?: JSX.Element
}


interface Props {
    setResetMethod?: React.Dispatch<React.SetStateAction<ResponseType>>
}
export interface ResetPasswordData {
	email: string,
    password: string,
    passwordConfirm: string,
    code: string,
}

export enum ResponseType {
	EMAIL = "EMAIL",
	SMS = "SMS"
}

enum LostPasswordResponse {
    NONE = "NONE",
    LOST_PASSWORD_FAILED = "LOST_PASSWORD_FAILED",
    LOST_PASSWORD_ERROR = "LOST_PASSWORD_ERROR"
}







const getQueryItem = (itemKey: string) => {
    const params = new URLSearchParams(window.location.search)
	const itemValue = params.get(itemKey)
	const item = itemValue ? itemValue : ''
    return item
}

enum PageState {
    FORM = 'FORM',
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL'
}

export const ResetPasswordForm = (props: Props): JSX.Element => {
    
    const [resetState, setResetState] = useState<PageState>(PageState.FORM);
    const email = getQueryItem('email');
    const code = getQueryItem('code');

    const {t, i18n} = useTranslation('common');
    const [responseError, setResponseError] = useState<LostPasswordResponse>()
    let navigate: NavigateFunction = useNavigate();
    const onFormSubmit = useCallback(async (values: ResetPasswordData, { setSubmitting }: any) => {
        const response = await resetPassword(values.email, values.password, values.code)
        var token = response.token ?? null;
        var error = response.error ?? null;
        var success = response.success ?? false;
        if (!error && success) {
            setResponseError(LostPasswordResponse.NONE)
            //props.setResetMethod(values.method)
            //window.location.hash = './login/LostPasswordConfirmed'
            navigate("/login");
            window.location.reload();
        } else if (error && !token) {
            // report back errors
            setResponseError(LostPasswordResponse.LOST_PASSWORD_FAILED)
        } else {
            // Special case?
            setResponseError(LostPasswordResponse.LOST_PASSWORD_ERROR)
        }

	}, []);
    
    const initialValues = {
        email: email,
        password: '',
        passwordConfirm: '',
        code: code,
    };
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        password: Yup.string(),
        passwordConfirm: Yup.string(),
        code: Yup.string()
      });

    return (
        <div>
        <Paper
            variant="elevation"
            elevation={0}
            className="login-background1"
        >
        <Grid item textAlign={("center")}>
            <Typography variant="h5" align="center" color="text.secondary" className="login-header" component="p" sx={{ padding: "0px 0 20px 0", }}>
                {t('pages.lostpassword.header', {framework:'React'})}
            </Typography>
        </Grid>
        <Grid item textAlign={("center")}>
            <p className="ch-text-center ch-mb-12">{t('pages.lostpassword.LostPasswordFormText')}</p>
            {
                (responseError === LostPasswordResponse.LOST_PASSWORD_FAILED) ?
                    <div id="checkin-fp-error" className="ch-text-center ch-p-3 ch-bg-red-200 ch-rounded">
                        <p>{t('pages.lostpassword.ErrorLostPassword')}</p>
                    </div>
                : (responseError === LostPasswordResponse.LOST_PASSWORD_ERROR) ?
                    <div id="checkin-fp-error" className="ch-text-center ch-p-3 ch-bg-red-200 ch-rounded">
                        <p>{t('pages.lostpassword.ErrorLostPassword')}</p>
                    </div>
                : null
            }
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onFormSubmit}
            >
                {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
                <Form>
                    <Box margin={1}>
                        <Field
                            component={CoreMuiTextField}
                            name="email"
                            type="email"
                            label={t('pages.resetPassword.input-label-email')}
                            helperText={t('pages.resetPassword.input-label-email')}
                        />
                    </Box>
                    <Box margin={1}>
                        <Field
                            component={CoreMuiTextField}
                            name="password"
                            type="password"
                            label={t('pages.resetPassword.input-label-password')}
                            helperText={t('pages.resetPassword.input-label-password')}
                        />
                    </Box>
                    <Box margin={1}>
                        <Field
                            component={CoreMuiTextField}
                            name="passwordConfirm"
                            type="password"
                            label={t('pages.resetPassword.input-label-passwordConfirm')}
                            helperText={t('pages.resetPassword.input-label-passwordConfirm')}
                        />
                    </Box>
                    <Box margin={1}>
                        <Field
                            name="code"
                            type="hidden"
                        />
                    </Box>
                    <Box margin={1}>
                        <Button
                            sx={{margin: 1}}
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                        {t('pages.resetPassword.input-label-submit')}
                        </Button>
                    </Box>
                </Form>
                )}
            </Formik>
        </Grid>
        </Paper>
        </div>
    )
}

export default ResetPasswordForm;