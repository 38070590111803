import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../services/EventBus";
import { getCurrentUser, checkAuth } from "./auth.service";
import { APP_API_URL, APP_API_URL_DEV } from "../Config/Constants";

var apiUrl = window.location.hostname.includes('localhost') ? APP_API_URL_DEV : APP_API_URL;
const API_URL = apiUrl+"/totals/";

function checkAuth2(response: any) {
  console.log(response);
  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      EventBus.dispatch("logout");
    }
}
}

  
export const getSummaryData = (period: string) => {
  checkAuth();
  var response = axios.get(API_URL + "summary?period="+period, { headers: authHeader() });
  return response;
};

export const getAccountsData = (period: string|null):any => {
  checkAuth();
  var response = axios.get(API_URL + "accounts?period="+period, { headers: authHeader() });
  return response;
};

export const getTransactionsData = (period: string|null) => {
  checkAuth();
  var response = axios.get(API_URL + "transactions?period="+period, { headers: authHeader() });
  return response;
};

export const getFixedExpensesData = (period: string) => {
  checkAuth();
  var response = axios.get(API_URL + "fixedExpenses?period="+period, { headers: authHeader() });
  return response;
};

export const getVariableExpensesData = (period: string) => {
  checkAuth();
  var response = axios.get(API_URL + "variableExpenses?period="+period, { headers: authHeader() });
  return response;
};
