// pages
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import PricingContent from "./pages/Pricing";
import TermsContent from "./pages/Terms";
import PrivacyContent from "./pages/Privacy";
import ContactContent from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Register from "./pages/Register";
import Dashboard from "./pages/my/Dashboard";
import Budget from "./pages/my/Budget";
import Setting from "./pages/my/Setting";
import { AuthCheck } from "./services/auth.service";
import React, { ReactElement, FC, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Oauth from "./pages/my/Oauth";
import How from "./pages/How";
import { Checkout } from "./pages/my/Checkout";
import { CheckoutReturn } from "./pages/my/CheckoutReturn";
import { DashboardTransactionsModal } from "./components/Elements/Dashboard/Modal/DashboardTransactionsModal";
import { DashboardAccountsEditModal } from "./components/Elements/Dashboard/Modal/DashboardAccountsEditModal";
import { DashboardAccountsSettingsModal } from "./components/Elements/Dashboard/Modal/DashboardAccountsSettingsModal";

// interface
export interface Route {
    key: string,
    title: string,
    title_key: string,
    path: string,
    enabled: boolean,
    authenticate: boolean,
    isAuthenticated: boolean,
    component: FC<{}>
}

export const routes: Array<Route> = [
    {
        key: 'home-route',
        title: 'Home',
        title_key: 'header.home',
        path: '/',
        enabled: true,
        authenticate: false,
        isAuthenticated: false,
        component: Home
    },
    {
        key: 'about-route',
        title: 'About',
        title_key: 'header.about',
        path: '/about',
        enabled: true,
        authenticate: false,
        isAuthenticated: false,
        component: About
    },
    {
        key: 'pricing-route',
        title: 'Pricing',
        title_key: 'header.pricing',
        path: '/pricing',
        enabled: true,
        authenticate: false,
        isAuthenticated: false,
        component: PricingContent
    },
    {
        key: 'how-route',
        title: 'Hvordan',
        title_key: 'header.how',
        path: '/how',
        enabled: false,
        authenticate: false,
        isAuthenticated: false,
        component: How
    },
    {
        key: 'privacy-route',
        title: 'Privacy',
        title_key: 'header.privacy',
        path: '/privacy',
        enabled: false,
        authenticate: false,
        isAuthenticated: false,
        component: PrivacyContent
    },
    {
        key: 'terms-route',
        title: 'Terms',
        title_key: 'header.terms',
        path: '/terms',
        enabled: false,
        authenticate: false,
        isAuthenticated: false,
        component: TermsContent
    },
    {
        key: 'contact-route',
        title: 'Contact',
        title_key: 'header.contact',
        path: '/contact',
        enabled: false,
        authenticate: false,
        isAuthenticated: false,
        component: ContactContent
    },
    {
        key: 'products-route',
        title: 'Products',
        title_key: 'header.products',
        path: '/products',
        enabled: false,
        authenticate: false,
        isAuthenticated: false,
        component: Products
    },
    {
        key: '404-route',
        title: '404',
        title_key: 'header.404',
        path: '*',
        enabled: false,
        authenticate: false,
        isAuthenticated: false,
        component: NotFound
    },/*
    {
        key: 'login-route',
        title: 'Login',
        title_key: 'header.login',
        path: '/login',
        enabled: false,
        authenticate: false,
        isAuthenticated: false,
        component: Login
    },*/
    {
        key: 'register-route',
        title: 'Register',
        title_key: 'header.register',
        path: '/register',
        enabled: false,
        authenticate: false,
        isAuthenticated: false,
        component: Register
    },
    {
        key: 'dashboard-route',
        title: 'Dashboard',
        title_key: 'header.my.dashboard',
        path: '/my/dashboard',
        enabled: true,
        authenticate: true,
        isAuthenticated: AuthCheck(true),
        component: Dashboard
    },
    {
        key: 'dashboard-transactions-route',
        title: 'Dashboard transactions',
        title_key: 'header.my.dashboard',
        path: '/my/dashboard/transactions/:id',
        enabled: false,
        authenticate: true,
        isAuthenticated: AuthCheck(true),
        component: DashboardTransactionsModal
    },
    {
        key: 'dashboard-accounts-route',
        title: 'Dashboard accounts',
        title_key: 'header.my.dashboard',
        path: '/my/dashboard/accounts/:id',
        enabled: false,
        authenticate: true,
        isAuthenticated: AuthCheck(true),
        component: DashboardAccountsEditModal
    },
    {
        key: 'dashboard-accounts-settings-route',
        title: 'Dashboard accounts settings',
        title_key: 'header.my.dashboard',
        path: '/my/dashboard/accounts/settings',
        enabled: false,
        authenticate: true,
        isAuthenticated: AuthCheck(true),
        component: DashboardAccountsSettingsModal
    },
    /*
    {
        key: 'taskboard-route',
        title: 'Taskboard',
        title_key: 'header.my.taskboard',
        path: '/my/taskboard',
        enabled: true,
        authenticate: true,
        isAuthenticated: AuthCheck(true),
        component: Taskboard
    },*/
    {
        key: 'budget-route',
        title: 'Budget',
        title_key: 'header.my.budget',
        path: '/my/budget',
        enabled: true,
        authenticate: true,
        isAuthenticated: AuthCheck(true),
        component: Budget
    },
    {
        key: 'checkout-route',
        title: 'Checkout',
        title_key: 'header.my.checkout',
        path: '/my/checkout',
        enabled: false,
        authenticate: true,
        isAuthenticated: AuthCheck(true),
        component: Checkout
    },
    {
        key: 'checkout-confirm-route',
        title: 'Checkout return',
        title_key: 'header.my.checkout-return',
        path: '/my/checkout-return',
        enabled: false,
        authenticate: true,
        isAuthenticated: AuthCheck(true),
        component: CheckoutReturn
    },
    {
        key: 'setting-route',
        title: 'Setting',
        title_key: 'header.my.setting',
        path: '/my/setting',
        enabled: false,
        authenticate: true,
        isAuthenticated: AuthCheck(true),
        component: Setting
    },
    {
        key: 'oauth-route',
        title: 'Oauth',
        title_key: 'header.my.oauth',
        path: '/my/oauth',
        enabled: false,
        authenticate: true,
        isAuthenticated: AuthCheck(true),
        component: Oauth
    }
];