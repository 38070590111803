import { ReactElement, FC, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {useTranslation} from "react-i18next";
import { COLOR_PINK_LIGHT_BG, COLOR_PINK_MAIN_TEXT, COLOR_WHITE } from "../Config/Constants";
import { illustration_relax_home } from '../assets';
import { getCurrentUser, AuthCheckRedirect } from "../services/auth.service";

const Home: FC<any> = (): ReactElement => {
  const {t, i18n} = useTranslation('common');
  
  // Navigate redirect to /my if user is set
  AuthCheckRedirect();
  //

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: COLOR_WHITE,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { xs: "20px", md: "120px" },
          paddingBottom: { xs: "40px", md: "250px" }
        }}
      >
        <Box component="main" sx={{  pt: 8, pb: 6 }}>
          <Typography align="center" color="primary.main" component="p" sx={{ 
            fontWeight:"700", 
            letterSpacing:"0.01em", 
            fontSize:{ xs: "40px", md: "40px" }, lineHeight:{ xs: "40px", md: "40px" }, paddingTop: "40px", paddingBottom: "150px" }}
          >
            {t('pages.home.h1-welcome1', {framework:'React'})}
            <br />
            {t('pages.home.h1-welcome2', {framework:'React'})}
            <br />
            {t('pages.home.h1-welcome3', {framework:'React'})}
          </Typography>
        </Box>
      </Box>
      
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: COLOR_PINK_LIGHT_BG,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { xs: "20px", md: "90px" },
          paddingBottom: { xs: "40px", md: "90px" }
        }}
      >
        <div className="container">
          <div className="row">
            
            <div className="col-sm col-lg-6 col-md-12  align-self-center content-block-text">
            <Box component="main" sx={{  pt: 0, pb: 0, pl: {xs: "0px", md: "220px"}}}>
              <Typography align="left" color="text.secondary" component="p" sx={{fontSize:{ xs: "22px", md: "22px" }, paddingBottom: "20px", }}>
                {t('pages.home.stress-free', {framework:'React'})}
              </Typography>
              <Typography align="left" color="secondary.main" component="p" sx={{ paddingBottom: "60px", }}>
                {t('pages.home.try-it-out', {framework:'React'})}
              </Typography>
              <Typography align="left" color="secondary.main" component="p" sx={{ paddingBottom: "10px", }}>
                <a href="/pricing"><Button variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                  {t('pages.home.button-get-started')}
                </Button></a>
              </Typography>
              <Typography align="left" color="secondary.main" component="p" sx={{ paddingBottom: "20px", }}>
                  <Link href="/how" variant="subtitle1" color="#00000"
                      sx={{ 
                      font: "Roboto",
                      fontWeight: "400",
                      textDecoration: "none",
                      fontSize: "0.9rem",
                      color: COLOR_PINK_MAIN_TEXT
                      }}
                      >
                      {t('pages.home.button-how-it-works')}
                  </Link>
              </Typography>
            </Box>
            </div>
            <div className="col-sm col-lg-6 col-md-12 image-block align-self-center">
              <div className="pl-20">
                <img src={illustration_relax_home} className="h-[225px]" alt="Relaxing with simple budget app" />
              </div>
            </div>
          </div>
        </div>
        
      </Box>


    </>
  );
};

export default Home;