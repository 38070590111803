import React, { ReactElement, FC, useCallback, useState } from "react";
import { Box, Typography, TextField, Paper, AppBar } from "@mui/material";
import { Formik, Field, Form, ErrorMessage, FormikHelpers, useFormik} from "formik";
import { useField } from 'formik';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import { LoginPageSwitch } from './LoginPageSwitch'
import { lostPassword } from "../../../services/auth.service";
import { getInitialValues, validationSchema } from './LostPasswordFormUtils'
import * as Yup from "yup";
import CoreTextInput from "../Core/CoreTextInput";
import CoreCheckbox from "../Core/CoreCheckbox";
import { CoreMuiTextField } from "../Core/CoreMuiTextField";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
    LinearProgress,
    MenuItem,
    FormControl,
    FormControlLabel,
    AutocompleteRenderInputParams,
    ToggleButton,
  } from '@mui/material';
  import {LocalizationProvider} from '@mui/lab';
  import AdapterDateFns from '@mui/lab/AdapterDateFns';
  import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
  import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
  import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
  import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
  import MuiTextField from '@mui/material/TextField';
  import {
    Autocomplete,
    Select,
    Switch,
    ToggleButtonGroup,
  } from 'formik-mui';
  import { lostPasswordOptionData } from "../Core/CoreData";
// Testing
import 'react-app-polyfill/ie11';
import * as ReactDOM from 'react-dom';
import { Windows } from "react-bootstrap-icons";

interface Props {
    setResetMethod?: React.Dispatch<React.SetStateAction<ResponseType>>
}
export interface LostPasswordData {
	email: string,
    confirm: boolean,
}

export enum ResponseType {
	EMAIL = "EMAIL",
	SMS = "SMS"
}

enum LostPasswordResponse {
    NONE = "NONE",
    LOST_PASSWORD_FAILED = "LOST_PASSWORD_FAILED",
    LOST_PASSWORD_ERROR = "LOST_PASSWORD_ERROR"
}
export const LostPasswordForm = (props: Props): JSX.Element => {
    const {t, i18n} = useTranslation('common');
    const [responseError, setResponseError] = useState<LostPasswordResponse>()
    let navigate: NavigateFunction = useNavigate();
    const onFormSubmit = useCallback(async (values: LostPasswordData, { setSubmitting }: any) => {
        const response = await lostPassword(values.email);
        var token = response.token ?? null;
        var error = response.error ?? null;
        var success = response.success ?? false;
        if (!error && success) {
            setResponseError(LostPasswordResponse.NONE)
            //props.setResetMethod(values.method)
            //window.location.hash = './login/LostPasswordConfirmed'
            navigate("/login/LostPasswordConfirmed");
            window.location.reload();
        } else if (error && !token) {
            // report back errors
            setResponseError(LostPasswordResponse.LOST_PASSWORD_FAILED)
        } else {
            // Special case?
            setResponseError(LostPasswordResponse.LOST_PASSWORD_ERROR)
        }

	}, []);
    
    const initialValues = {
        email: '',
        confirm: false,
        resetMethod: [],
    };
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        confirm: Yup.boolean().isTrue(),
        resetMethod: Yup.array().required(),
      });

      /*
    const onFormSubmit = (values: any, { setSubmitting }: any) => {
        // console.log('submitting');
        setTimeout(() => {
          setSubmitting(false);
          // eslint-disable-next-line no-alert
          alert(JSON.stringify(values, null, 2));
        }, 500);
        console.log(values);
      };
      */
      // Alternative text input form
      /*
        <CoreMuiTextField
            label={t('pages.lostpassword.input-label-email')}
            fieldName="email"
            fieldType="email"
            name="email"
            placeholder={t('pages.lostpassword.input-label-email')}
            
        />
        <CoreTextInput
            label={t('pages.lostpassword.input-label-email')}
            name="email"
            type="email"
            placeholder={t('pages.lostpassword.input-label-email')}
        />
        <Field
            label={t('pages.lostpassword.input-label-email')}
            id="347843bdwt47"
            name="email"
            component={CoreInputText}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
        />
        <Formik>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        </Fomik>
        // https://codesandbox.io/s/915qlr56rp?file=/src/index.tsx:917-937
      */
    return (
        <div>
        <Paper
            variant="elevation"
            elevation={0}
            className="login-background1"
        >
        <Grid item textAlign={("center")}>
            <Typography variant="h5" align="center" color="text.secondary" className="login-header" component="p" sx={{ padding: "0px 0 20px 0", }}>
                {t('pages.lostpassword.header', {framework:'React'})}
            </Typography>
        </Grid>
        <Grid item textAlign={("center")}>
            <p className="ch-text-center ch-mb-12">{t('pages.lostpassword.LostPasswordFormText')}</p>
            {
                (responseError === LostPasswordResponse.LOST_PASSWORD_FAILED) ?
                    <div id="checkin-fp-error" className="ch-text-center ch-p-3 ch-bg-red-200 ch-rounded">
                        <p>{t('pages.lostpassword.ErrorLostPassword')}</p>
                    </div>
                : (responseError === LostPasswordResponse.LOST_PASSWORD_ERROR) ?
                    <div id="checkin-fp-error" className="ch-text-center ch-p-3 ch-bg-red-200 ch-rounded">
                        <p>{t('pages.lostpassword.ErrorLostPassword')}</p>
                    </div>
                : null
            }
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onFormSubmit}
            >
                {({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
                <Form>
                    <Box margin={1}>
                        <Field
                        component={CoreMuiTextField}
                        name="email"
                        type="email"
                        label="Email"
                        helperText={t('pages.lostpassword.input-helper-email')}
                        />
                    </Box>
                    <Box margin={1}>
                        <FormControlLabel
                        control={
                            <Field component={Switch} type="checkbox" name="confirm" />
                        }
                        label={t('pages.lostpassword.confirm')}
                        />
                    </Box>
                    
                    
                    <Box margin={1}>
                        <Field
                        name="resetMethod"
                        multiple
                        component={Autocomplete}
                        options={lostPasswordOptionData}
                        getOptionLabel={(option: any) => option.title}
                        style={{width: 300}}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <MuiTextField
                            {...params}
                            name="resetMethod"
                            error={touched['resetMethod'] && !!errors['resetMethod']}
                            helperText={touched['resetMethod'] && errors['resetMethod']}
                            label={t('pages.lostpassword.resetMethod')}
                            variant="outlined"
                            />
                        )}
                        />
                    </Box>
                    <Box margin={1}>
                        <Button
                        sx={{margin: 1}}
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                        >
                        {t('pages.lostpassword.input-label-submit')}
                        </Button>
                        <Button
                        sx={{margin: 1}}
                        variant="contained"
                        color="secondary"
                        disabled={isSubmitting}
                        onClick={() => {
                            resetForm();
                        }}
                        >
                        {t('pages.lostpassword.input-label-reset')}
                        </Button>
                    </Box>
                    {/*<pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </Form>
                )}
            </Formik>
        </Grid>
        </Paper>
        </div>
    )
}

export default LostPasswordForm;