import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {useTranslation} from "react-i18next";
import { getAccountedData } from "../../../services/budget.service";
import { IUserBudgetData, IBudget, ITransaction, IBudgetCategory } from "../../../components/DataModels/Budget/UserBudgetModel";
import { CoreModalBasic } from "../../Elements/Core/CoreModalBasic";
import { ViewTransactionsList } from "./ViewTransactionsList";
import { ViewEditBudgetCategory } from "./ViewEditBudgetCategory";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface Props {
    userBudget: any,
    budgetAccountedLine: any,
    className: any,
    period: any,
    idx: any,
    button?: any,
    isSubLine?: boolean,
    parentCallback(callback:any): any
}
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip arrow placement="top-end" {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

export const TableRow = ({...props}: Props) => {
    const {t, i18n} = useTranslation('common');
    var budgetAccountedLine = props.budgetAccountedLine;
    var className = props.className;
    var userBudget = props.userBudget;
    var isSubLine = props.isSubLine ?? false;
    var period = props.period;
    var idx = props.idx;
    var button = props.button ?? null;
    
    const [showRow, setShowRow] = useState<boolean>(false);
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    }
    const handleShowRow = (date: any) => {
        setShowRow(!showRow);
    };
    var ButtonIcon = showRow ? ExpandLessOutlinedIcon : ExpandMoreOutlinedIcon;
    var budgetCategory = userBudget.find((e:IBudget) => e.account === budgetAccountedLine.account);
    var isFixedExpense = (budgetCategory?.expenseType == 1 || budgetCategory?.account == 3100);
    var amountSpentColor = (budgetCategory?.amount + budgetAccountedLine.amount < 0) ? "red" : "green";
    if (isSubLine) {
        amountSpentColor = "black";
    }
    const handleCallback = (callback:any) => {
        // Update the name in the component's state
        if(callback.closeModal == true) {
            console.log("Modal closed")
            props.parentCallback({closeModal : true});
        }
    };

    return(
        <>
        <tr className={className}  id={budgetAccountedLine.account} key={idx}>
            <td>
                <Grid container spacing={{ xs: 0.1, md: 0.1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                    
                    <Grid item xs={5} md={8} >
                    <Grid container spacing={{ xs: 0.1, md: 0.1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                        <Grid item xs={2} md={1} >
                            
                            {!isSubLine && 
                            (  
                                <IconButton onClick={handleShowRow} aria-label="delete" size="small" color="primary">
                                    <ButtonIcon fontSize="inherit" />
                                </IconButton>
                            )
                            }
                        </Grid>
                        <Grid item xs={10} md={11} >
                            {budgetAccountedLine.description.substring(0, 15)}
                        </Grid>
                    </Grid>
                    </Grid>


                    


                    <Grid item xs={5} md={3} >
                        <Grid container spacing={{ xs: 0.1, md: 0.1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                        <Grid item xs={6} md={6} >
                            <div style={{ color: amountSpentColor }}>{budgetAccountedLine.amount}</div>
                        </Grid>
                        <Grid item xs={6} md={6} >
                            { !isSubLine && ( " / " + budgetCategory?.amount )  }
                        </Grid>
                    </Grid>
                    </Grid>





                    <Grid item xs={2} md={1}>
                    <Grid container spacing={{ xs: 0.1, md: 0.1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                        <Grid item xs={6} md={6} >

                            {!isSubLine && (
                            <CoreModalBasic parentCallback={handleCallback} iconButton={<EditIcon fontSize="inherit" />} iconSize="small" header={t('pages.my.budget.modal.edit-budget-category-heading')}>
                                <ViewEditBudgetCategory budgetCategory={budgetCategory} budgetAccountedLine={budgetAccountedLine} isSubLine={isSubLine} />
                            </CoreModalBasic>
                            )}
                            {isSubLine && (
                            <>
                            <CoreModalBasic parentCallback={() =>{}} iconButton={<InfoOutlinedIcon fontSize="inherit" />} iconSize="small" header={budgetAccountedLine.description} subheader={period}>
                                <ViewTransactionsList budgetAccountedLine={budgetAccountedLine} period={period} />
                            </CoreModalBasic>
                            </>
                            )
                            }
                        </Grid>
                        <Grid item xs={6} md={6}>
                            { isFixedExpense && !isSubLine && (
                                <CustomWidthTooltip title={t('pages.my.budget.table-row-is-fixed-expense-text')}>
                                    <LockIcon fontSize="inherit" />
                                </CustomWidthTooltip>
                            )}
                            { !isFixedExpense && !isSubLine && ( 
                                <CustomWidthTooltip title={t('pages.my.budget.table-row-is-not-fixed-expense-text')}>
                                    <LockOpenIcon fontSize="inherit" />
                                </CustomWidthTooltip>
                            )}
                        </Grid>
                    </Grid>
                    </Grid>





                </Grid>
            </td>
        </tr>
        {showRow && (budgetAccountedLine.accounts?.map((subItem:IBudget, idy:any) => (
            <TableRow parentCallback={handleCallback} budgetAccountedLine={subItem} key={idy} className={"sub-item c-item child-"+budgetAccountedLine.account} userBudget={userBudget} isSubLine={true} idx={idy} period={period} />
        )))}
        </>
    );
}

export const TableRows = ({...props}: any) => {
    const {t, i18n} = useTranslation('common');
    var userBudgetAccounted = props.userBudgetAccounted ?? [];
    var budgetAccountedLine = props.budgetAccountedLine ?? [];
    var userBudget = props.userBudget ?? [];
    var period = props.period;
    var idx = props.idx;
    let totalBudgetExpense:any = 0; // not used
    let totalBudgetUsed:any = 0;
    let totalBudgetIncome:any = 0;
    let fixedAccounts: string[] = [];
    let fixedExpenseBudgetSum = 0;
    let fixedExpenseSum = 0;
    let variableAccounts: string[] = [];
    let variableExpenseBudgetSum = 0;
    let variableExpenseSum = 0;

    userBudget.forEach((item:IBudgetCategory) => {
        if(item.account == "3100") {
            totalBudgetIncome = totalBudgetIncome + item.amount;
        } else {
            totalBudgetExpense = totalBudgetExpense + item.amount;
            if(item.expenseType == 1) {
                fixedAccounts.push(item.account);
                fixedExpenseBudgetSum += item.amount;
            } 
            else {
                variableAccounts.push(item.account);
                variableExpenseBudgetSum += item.amount;
            }
        }
    });
    userBudgetAccounted.forEach((item:IBudget) => {
        if(item.account != "3100") {
            totalBudgetUsed = totalBudgetUsed - item.amount;
        }
        if(fixedAccounts.includes(item.account)){
            fixedExpenseSum += item.amount;
        }
        if(variableAccounts.includes(item.account)){
            variableExpenseSum += item.amount;
        }
    });
    
    var fixedExpenseSumColor = (Math.round(Math.abs(fixedExpenseSum)) > Math.round(Math.abs(fixedExpenseBudgetSum))) ? "red" : "green";
    var variableExpenseSumColor = (Math.round(Math.abs(variableExpenseSum)) > Math.round(Math.abs(variableExpenseBudgetSum))) ? "red" : "green";

    var amountTotalSpentColor = (Math.round(Math.abs(totalBudgetUsed)) > Math.round(Math.abs(totalBudgetIncome))) ? "red" : "green";
    var totalBudgetRestAmountColor = (Math.round(Math.abs(totalBudgetExpense)) > Math.round(Math.abs(totalBudgetIncome))) ? "red" : "green";
    var totalBudgetRestAmount = Math.round((totalBudgetIncome - totalBudgetExpense) * 100)/100;

    const handleCallback = (callback:any) => {
        if(callback.closeModal == true) {
            //console.log("Modal closed")
            props.parentCallback({closeModal : true});
        }
    };

    const [hiddenTooltip, setHiddenTooltip] = useState(true);

    return(
        <>
        <table className="table budget-list">
            <thead>
                <tr key="header">
                    <th>
                        <Grid container spacing={{ xs: 0.1, md: 0.1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                        
                            <Grid item xs={5} md={8} >
                            {t('pages.my.budget.table-head-account')}
                            </Grid>
                            <Grid item xs={5} md={4} >
                            {t('pages.my.budget.table-head-amount-used')} {" / "} {t('pages.my.budget.table-head-amount-budget')}
                            </Grid>
                        </Grid>
                        
                    
                    
                    
                    </th>
                </tr>
            </thead>
            <tbody>
                {userBudgetAccounted?.map((budgetAccountedLine: IBudget, idx: any) => (
                    <TableRow parentCallback={handleCallback} budgetAccountedLine={budgetAccountedLine}  key={idx} className="main-item c-item" userBudget={userBudget} idx={idx} button={null} period={period} />
                ))}
                <tr className="sub-itemd" id="income">
                    <td>
                        <Grid container spacing={{ xs: 0.1, md: 0.1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                            
                            <Grid item xs={5} md={8} >
                                <div>
                                    <div style={{float: "left"}}>{t('pages.my.budget.table-sum-head-budget-amount')}</div>
                                    <div> &nbsp; </div>

                                    <div>{t('pages.my.budget.table-budget-sum-fixed-expenses')} {": "} </div>
                                    <div>{t('pages.my.budget.table-budget-sum-variable-expenses')} {": "} </div>
                                    {(!hiddenTooltip || true) && totalBudgetRestAmount > 0 && (<div style={{position: "absolute", display: "inline-block", color: totalBudgetRestAmountColor }}> {t('pages.my.budget.table-budget-rest-amount-left')} {totalBudgetRestAmount}</div>)}
                                    {(!hiddenTooltip || true) && totalBudgetRestAmount < 0 && (<div style={{position: "absolute", display: "inline-block", color: totalBudgetRestAmountColor }}> {t('pages.my.budget.table-budget-rest-amount-overbudgeted')} {totalBudgetRestAmount}</div>)}
                                    {Math.abs(totalBudgetRestAmount) > 0 && false && (
                                    <div  
                                        style={{float: "left", color: totalBudgetRestAmountColor }}
                                        onMouseEnter={() => setHiddenTooltip(false)}
                                        onMouseLeave={() => setHiddenTooltip(true)}
                                    > /({totalBudgetRestAmount})</div>)} 


                                </div>
                            </Grid>
                            <Grid item xs={7} md={4} >
                                <Grid container spacing={{ xs: 0.1, md: 0.1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                                    <Grid item xs={5} md={5} >
                                        <div style={{ color: amountTotalSpentColor }}>{Math.round(totalBudgetUsed)}</div>
                                        
                                        <div style={{ color: fixedExpenseSumColor }}>{Math.round(Math.abs(fixedExpenseSum))}</div>
                                        <div style={{ color: variableExpenseSumColor }}>{Math.round(Math.abs(variableExpenseSum))}</div>
                                        
                                    </Grid>
                                    <Grid item xs={7} md={7} >
                                        <div style={{float: "left"}}>{totalBudgetIncome}</div> 
                                        
                                        <div> &nbsp; </div>

                                        <div>{Math.round(fixedExpenseBudgetSum)} <LockIcon fontSize="inherit" /></div>
                                        <div>{Math.round(variableExpenseBudgetSum)} <LockOpenIcon fontSize="inherit" /></div>

                                        {!hiddenTooltip && false && totalBudgetRestAmount > 0 && (<div style={{position: "relative", display: "inline-block", color: totalBudgetRestAmountColor }}> /({t('pages.my.budget.table-budget-rest-amount-left')})</div>)}
                                        {!hiddenTooltip && false && totalBudgetRestAmount < 0 && (<div style={{position: "relative", display: "inline-block", color: totalBudgetRestAmountColor }}> /({t('pages.my.budget.table-budget-rest-amount-overbudgeted')})</div>)}
                                    </Grid>
                                </Grid>
                            
                            </Grid>
                        </Grid>
                        
                    </td>
                </tr>
            </tbody>
        </table>
        </>
    );
}


export const UserBudgetTable = ({period}: any) => {
    const {t, i18n} = useTranslation('common');
    const [userBudgetData, setUserBudgetData] = useState<IUserBudgetData>();
    const [userBudget, setUserBudget] = useState<[IBudget]>();
    const [userBudgetAccounted, setUserBudgetAccounted] = useState<[IBudget]>();
    async function fetchData() {
        const response = await getAccountedData(period);
        const json = await response.data;
        setUserBudgetData(json.data);
        setUserBudget(json.data.budget);
        setUserBudgetAccounted(json.data.budgetAccounted);
    }
    useEffect(() => {
        fetchData();
    }, [period]);
    

    const handleCallback = (callback:any) => {
        if(callback.closeModal == true) {
            console.log("update data")
            fetchData();
        }
    };

    return (
        <div className="">
            <TableRows parentCallback={handleCallback} userBudgetAccounted={userBudgetAccounted} userBudget={userBudget} period={period} />
        </div>
    );
}

export default UserBudgetTable;
