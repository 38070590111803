import axios from "axios";
import React, { ReactElement, FC, useCallback, useState } from "react";
import { bool } from "yup";
import {Route} from "../routes";
import EventBus from "../services/EventBus";
import authHeader from "./auth-header";
import { getCurrentUser, checkAuth } from "./auth.service";
import jwt_decode from "jwt-decode";
import { APP_API_URL, APP_API_URL_DEV } from "../Config/Constants";
import {IUser, User} from "../components/DataModels/User/User";

var apiUrl = window.location.hostname.includes('localhost') ? APP_API_URL_DEV : APP_API_URL;
const API_URL = apiUrl+"/checkout/";

export interface CreateCheckoutSessionResponse {
    url: string,
}
export interface CancelSubscriptionResponse {
  result: boolean,
  CancelSubscription: Subscription
}
export interface Subscription {
  status: string,
  activeAt: string,
  cancelAt: string,
  title: string,
  amount: any,
  currentPeriodStart: string,
  CurrentPeriodEnd: any,
  Invoices: any
} 


/*
export const createCheckoutSession = useCallback(() => {
  // Create a Checkout Session
  return fetch(API_URL + "create-checkout-session", {
    method: "POST",
    headers: authHeader()
  })
    .then((res) => res.json())
    .then((data) => data.clientSecret);
}, []);
*/

export const createCheckoutSession = (product:string) => {
  return axios
    .post(API_URL + "createCheckoutSession", {
      product,
    }, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

export const getSubscriptionStatus = () => {
  checkAuth();
  var response = axios.get(API_URL + "subscriptionStatus", { headers: authHeader() });
  return response;
};

export const cancelSubscription = () => {
  return axios
    .post(API_URL + "cancelSubscription", {
    }, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};
