import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../services/EventBus";
import { getCurrentUser, checkAuth } from "./auth.service";
import { APP_API_URL, APP_API_URL_DEV } from "../Config/Constants";

var apiUrl = window.location.hostname.includes('localhost') ? APP_API_URL_DEV : APP_API_URL;
const API_URL = apiUrl+"/accounting/";

function checkAuth2(response: any) {
  console.log(response);
  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      EventBus.dispatch("logout");
    }
}
}


export const getTransactionData = (id: any) => {
  checkAuth();
  var response = axios.get(API_URL + "transaction?id="+id, { headers: authHeader() });
  return response;
};

export const editTransaction = (id:string, categoryId: string, textRule: string, lock: string) => {
  return axios
    .post(API_URL + "editTransaction", {
      id,
      categoryId,
      textRule,
      lock,
    }, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      
      return response.data;
    });
};
